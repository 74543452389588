.dashboard {
  width: 100%;
  height: auto;
}

.table-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.table-responsive::-webkit-scrollbar {
  height: 5px;
  background-color: #f5f5f5;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #30679a;
}

.dashboard_body_content {
  /* display: none; */
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 84px;
}
. {
  position: relative;
}
#contents {
  position: relative;
  transition: 0.3s;
  margin-left: 260px;
  padding: 20px;
  /* background-color: #fff; */
  background: transparent linear-gradient(180deg, #f0f0f7 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
}
.margin {
  margin-left: 50px !important;
}
/* Start side navigation bar  */
#top_nav_toggle {
  position: absolute;
  left: -84px;
  top: 65px;
  height: 100%;
}
#top_nav_toggle .side-nav {
  min-height: 100vh;
}
.side-nav {
  position: fixed;
  float: left;
  min-height: 100%;
  width: 260px;
  background-color: #fff;
  color: #ccc;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: 0.3s;
  left: 0;
  box-shadow: 5px 0px 10px #0000001a;
}
.side-nav .close-aside {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
  color: #eee;
}
.side-nav .heading {
  background-color: #252636;
  padding: 15px 15px 15px 30px;
  overflow: hidden;
  border-bottom: 1px solid #2a2b3c;
}
.side-nav .heading > img {
  border-radius: 50%;
  float: left;
  width: 28%;
}
.side-nav .info {
  float: left;
  width: 69%;
  margin-left: 3%;
}
.side-nav .heading .info > h3 {
  margin: 0 0 5px;
}
.side-nav .heading .info > h3 > a {
  color: #eee;
  font-weight: 100;
  margin-top: 4px;
  display: block;
  text-decoration: none;
  font-size: 18px;
}
.side-nav .heading .info > h3 > a:hover {
  color: #fff;
}
.side-nav .heading .info > p {
  color: #bbb;
  font-size: 13px;
}
/* End heading */
/* Start search */
.side-nav .search {
  text-align: center;
  padding: 0 15px;
  margin: 10px 0;
  position: relative;
}
.dropdown-btn{

}
#dropdown-basic-two,
#dropdown-basic-two button:hover,
#dropdown-basic-two button:focus {
  background: transparent !important;
  border: none !important;
}
.side-nav .search .input-group-text {
  background: transparent;
  border-color: #c4c4c4;
  border-right: 0px !important;
  height: 38px;
  margin-left: -37px !important;
}
.side-nav .search input {
  background-color: transparent;
  border: none;
  border: 1px solid #c4c4c4 !important;
  border-left: 0px !important;
  padding: 7px 0px;
  color: #989898 !important;
  font-size: 15px;
}
.side-nav .search > input ~ i {
  position: absolute;
  top: 22px;
  right: 40px;
  display: block;
  color: #2b2f3a;
  font-size: 19px;
}
.side-nav .search input::placeholder {
  color: #989898 !important;
}
.suppliers_side_nav.wid-2 .search {
  padding: 0px !important;
  overflow: hidden;
}
/* End search */
.side-nav .categories > li {
  width: 100%;
  height: auto;
  float: left;
}
.side-nav .categories > li a {
  padding: 17px 40px 17px 20px;
  overflow: hidden;
  color: #30679a;
  border-bottom: 1px solid rgba(255, 255, 255, 0.02);
  cursor: pointer;
  border-left: 5px solid #fff;
  width: 100%;
  float: left;
  font-size: 15px;
  border-bottom: 0px;
}
.side-nav .categories > li a img {
  padding-right: 8px;
}
/* Start num: there are three options primary, danger and success like Bootstrap */
.side-nav .categories > li > a > .num {
  line-height: 0;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  padding: 0px 5px;
}
.dang {
  background-color: #f35959;
}
.prim {
  background-color: #0275d8;
}
.succ {
  background-color: #5cb85c;
}
/* End num */

.side-nav .categories > li a:hover,
.side-nav .categories > li a.active {
  background: #30679a73;
  border-color: #30679a;
  border-bottom: 0px;
}
.side-nav .categories > li a:hover {
  color: #30679a;
}
.side-nav .categories > li > i {
  font-size: 18px;
  margin-right: 5px;
}
.height-38 {
  height: 38px;
}
.width-45 {
  width: 45px;
  padding-left: 12px;
}
/* .side-nav .categories > li > a:after {
    content: "\f053";
    font-family: fontAwesome;
    font-size: 11px;
    line-height: 1.8;
    float: right;
    color: #AAA;
    transition: all .3s ease-in-out;
  }
  .side-nav .categories .opend > a:after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  } */
/* End categories */
/* Start dropdown menu */
.side-nav .categories .side-nav-dropdown {
  padding-top: 10px;
  padding-left: 30px;
  list-style: none;
  display: none;
}
.side-nav .categories .side-nav-dropdown > li > a {
  color: #aaa;
  text-decoration: none;
  padding: 7px 0;
  display: block;
}
.side-nav .categories p {
  margin-left: 30px;
  color: #535465;
  margin-top: 10px;
}

/* End dropdown menu */

.show-side-nav {
  -webkit-transform: translateX(-210px);
  transform: translateX(-210px);
  position: absolute;
  top: 0;
}
.show-side-nav ul li a img {
  position: absolute;
  right: 12px;
}

.show-side-nav ul li a img.hide--toggle {
  display: none !important;
}

/* my dashboard css */
.body_content {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  padding: 25px 10px 20px;
  min-height: calc(157vh - 36px) !important;
}
.body_scroll {
  min-height: calc(90vh - 36px) !important;
}
.dash_top_navbar .navbar {
  box-shadow: 1px 2px 6px #00000026;
  background: #fff !important;
  z-index: 999;
}
.dash_top_navbar .navbar-expand-sm .navbar-collapse {
  display: block !important;
}
.dash_top_navbar .navbar-brand {
  margin-right: 0px !important;
}
.external_modal_link {
  color: #30679a !important;
  font-size: 30px;
  font-family: "SegoeSemiBold";
  line-height: 23px;
  margin-right: 5px;
}
.external_modal_link img {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}
.user_menu {
  color: #4d4f5c;
  font-size: 13px;
  float: left;
  padding-right: 5px;
}
.user_menu:hover {
  color: #4d4f5c;
}
.user_menu .dash_down_caret {
  margin: 0px 11px 0px 15px;
}
.user-profile.user_drop_options span {
  float: right;
  cursor: pointer;
}
.user-profile.user_drop_options span img {
  height: 38px;
  width: 38px;
  border-radius: 50%;
}
.dash_menu_item {
  margin-top: -5px;
}
.dash_menu_toggle {
  position: absolute;
  right: 17px;
  top: 20px;
  cursor: pointer;
}
#top_nav_toggle1 {
  z-index: 999 !important;
  position: fixed;
  top: 84px;
}
#top_nav_toggle1 .side-nav {
  border-top: 2px solid #235d92;
  z-index: 9;
  min-height: 136vh;
  transform: translateX(0) !important;
}
.side-nav.show-side-nav .side-nav .categories > li:first-child {
  padding: 17px 18px 17px 20px !important;
  margin-top: 40px !important;
}
.side-nav.show-side-nav .side-nav .categories > li a {
  display: none !important;
}
.side-nav.show-side-nav .side-nav .categories > li a img {
  float: right !important;
}
.home_stat_table {
  margin-bottom: 0px !important;
  border-spacing: 0px 10px !important;
  border-collapse: separate;
  padding: 10px;
}
.home_stat_table thead th {
  border: 0px;
  color: #656565;
  font-size: 18px;
  text-align: center;
  padding-top: 0px;
}
.home_stat_table tbody tr th {
  border: 0px;
  color: #656565;
  font-size: 15px;
  width: 5%;
  vertical-align: middle;
}
.home_stat_table tbody tr td {
  border: 0px;

  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  padding: 0px;
}
.home_stat_table tbody tr td:nth-child(2) {
  border-radius: 5px 0px 0px 5px;
}
.home_stat_table tbody tr td:last-child {
  border-radius: 0px 5px 5px 0px;
}
.home_stat_table tbody tr td span {
  width: 100%;
  height: auto;
  float: left;
  color: #30679a;
  font-size: 38px;
  text-align: center;
  font-family: "SegoeBold";
  padding: 0px 0.75rem;
  margin: 5px 0px;
  border-right: 1px solid #95989a;
}
.home_stat_table tbody tr td:last-child span {
  border-right: 0px;
}
.home_stat_table tbody tr td:nth-child(2) {
  border-left: 1px solid #f3f3f3;
}
.home_stat_table tbody tr td:last-child {
  border-right: 1px solid #f3f3f3;
}
/* .home_stat_table tbody tr{border-bottom: 30px solid #fff;} */
.gray_close {
  position: absolute;
  right: 25px;
  top: 20px;
  z-index: 99;
  cursor: pointer;
}
.recent_actives {
  color: #656565;
  font-size: 18px;
}
#recent_activity {
  position: fixed;
  top: 83px;
  width: 30vw;
  left: 0px;
  background: #fff;
  box-shadow: 0px 0px 6px #00000026;
  padding: 10px;
  z-index: 999;
}
#recent_activity .forgot_body.recent_act {
  box-shadow: none !important;
}
.user_drop_options .dropdown-menu .dropdown-item {
  font-size: 20px;
  color: #5a5a5a;
}
.user_drop_options .dropdown-menu.dropdown-menu-right.show {
  width: 267px !important;
}

.drop__dash--clickbuton.dash.analysis-card-dropdwn.show .dropdown-menu.show {
  top: inherit !important;
  left: -90px !important;
  bottom: -30px !important;
}
.user_drop_options .dropdown-menu .dropdown-item.active,
.user_drop_options .dropdown-menu .dropdown-item:active {
  background-color: #ededf4 !important;
  outline: none !important;
}
.home_table {
  box-shadow: 0px 2px 6px #00000026 !important;
  border-radius: 5px;
  margin-bottom: 20px;
}
#top_nav_toggle1 .dash_menu_toggle {
  position: relative !important;
  right: auto !important;
  top: auto !important;
  cursor: pointer;
  float: right;
}
#top_nav_toggle1 .side-nav .categories > li:first-child a {
  padding-right: 20px !important;
}
.dash.analysis-card-dropdwn button {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
}

.analysis-card-dropdwn button,
.analysis-card-dropdwn button:hover,
.analysis-card-dropdwn button:focus,
.analysis-card-dropdwn button:active,
.analysis-card-dropdwn button::selection,
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background: transparent !important;
  text-align: right;
}
#dropdown-basic,
#dropdown-basic:hover {
  background: transparent !important;
}
.dash.analysis-card-dropdwn.show .dropdown-menu.show {
  top: 15px !important;
  left: -70px !important;
  width: 215px;
}

.drop__dash--clickbuton button {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  border: none !important;
}

/* my dashboard css end*/

/* recent activity */
.recent_active_main.comments_main {
  border-bottom: 1px solid #e3e3e3 !important ;
  padding: 14px 0px 10px 0px !important;
}
.recent_act {
  width: 100%;
  height: auto;
  position: relative;
  box-shadow: 0px 2px 6px #00000026;
  border-radius: 5px;
  padding: 10px;
}
.recent_act .gray_close {
  top: 10px !important;
  right: 10px !important;
}
.activity_9 p {
  color: #95989a;
  font-size: 20px;
  display: inline-block;
}
trans_price {
  font-size: 13px;
}

.activity_3 p {
  color: #95989a;
  font-size: 16px;
}
.activity_9_h5 {
  color: #235d92;
  font-family: "SegoeBold";
  position: relative;
  right: 20px;
  margin-left: 21px;
}
.forgot_body .col-md-12:last-child .activity_item_main.comments_main {
  border-bottom: 1px solid #e3e3e3 !important ;
}
.comment_textarea {
  margin-top: 0px;
  margin-bottom: 16px;
  height: 77px;
  resize: none;
}
.add_comment_btn {
  margin-left: 0px !important;
}
.comment_section {
  padding: 14px 0px 10px 0px;
}
.recent_active_main {
  padding: 0px 0px 27px 0px !important;
  border: none !important;
}

.recent_active_main .activity_9_h5 {
  margin-bottom: 2px !important;
}
.recent_active_main .activity_9_h5,
.recent_active_main .activity_9 p {
  font-size: 13px;
  margin-bottom: 0px;
}
.recent_active_main .activity_3 p {
  font-size: 10px;
  font-family: "SegoeBold";
  margin-bottom: 0px;
}
.forgot_body.rececnt_tab
  .col-md-12:last-child
  .activity_item_main.comments_main,
.forgot_body.recent_act
  .col-md-12:last-child
  .activity_item_main.comments_main {
  border-bottom: 0px !important;
}

/* transaction section  */
.recent_active_main.tans_history {
  border: 1px solid #f3f3f3 !important;
  padding: 10px !important;
  border-radius: 5px;
  margin-bottom: 5px;
}
.tans_history.purpal {
  border-left: 4px solid #665eff !important;
}
.tans_history.yellow {
  border-left: 4px solid #ffb900 !important;
}
.tans_history.orange {
  border-left: 4px solid #ff9057 !important;
}
.tans_history.dark_blue {
  border-left: 4px solid #5773ff !important;
}
.tans_history.gray {
  border-left: 4px solid #78849e !important;
}
.tans_history.light_blue {
  border-left: 4px solid #3acce1 !important;
}
.tans_history .activity_9_h5 {
  color: #707070 !important;
  font-family: "SegoeRegular" !important;
  margin-bottom: 0px !important;
  top: 4px;
}
.activity_9 span.trans_date_time {
  width: 100%;
  float: left;
  font-size: 11px;
  color: #707070;
  font-family: "SegoeRegular" !important;
}
.activity_9 span.trans_date_time:first-child {
  margin-top: 18px;
}
.tans_history p {
  font-family: "SegoeRegular" !important;
  color: #707070 !important;
}
.forgot_body
  .col-md-12:last-child
  .activity_item_main.comments_main.tans_history {
  border-bottom: 1px solid #e3e3e3 !important;
}
.mm_sticky_menu
  .drop__dash--clickbuton.dash.analysis-card-dropdwn.show
  .dropdown-menu.show {
  bottom: -26px !important;
  right: 70px !important;
  left: auto !important;
}
/* end */

/* Start media query */
@media (max-width: 767px) {
  .side-nav .categories > li {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .side-nav .search {
    padding: 10px 10px 10px 30px;
  }
}

/* End side navigation bar  */
/* Start welcome */

.welcome {
  color: #ccc;
  margin-bottom: 25px;
}
.welcome .content {
  position: relative;
  background-image: url(../../Assets/images/welcome-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0px 15px;
  text-align: center;
  border-radius: 5px;
}
.welcome .content .white_close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
  cursor: pointer;
}
.welcome h2 {
  color: #fff;
  font-size: 30px;
  font-weight: 100;
  margin-top: 0;
  font-family: "SegoeBold";
  margin-bottom: 20px;
}
.welcome p {
  color: #fff;
  font-size: 23px;
  width: 44%;
  margin: 0 auto;
}

/* Start statistics */
.statistics {
  color: #ccc;
}
.statistics .box {
  background-color: #313348;
  padding: 15px;
  overflow: hidden;
}
.statistics .box > i {
  float: left;
  color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 22px;
}
.statistics .box .info {
  float: left;
  width: auto;
  margin-left: 10px;
}
.statistics .box .info h3 {
  margin: 5px 0 5px;
  display: inline-block;
}
.statistics .box .info p {
  color: #bbb;
}

/* End statistics */
/* Start charts */
.charts {
  margin-top: 25px;
  color: #bbb;
}
.charts .chart-container {
  background-color: #313348;
  padding: 15px;
}
.charts .chart-container h3 {
  margin: 0 0 10px;
  font-size: 17px;
}

/* Start users */

.admins {
  margin-top: 25px;
}

.admins .box > h3 {
  color: #ccc;

  font-weight: 300;
  margin-top: 0;
}
.admins .box .admin {
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #313348;
  padding: 10px;
}
.admins .box .admin .img {
  width: 20%;
  margin-right: 5%;
  float: left;
}
.admins .box .admin .img img {
  border-radius: 50%;
}
.admins .box .info {
  width: 75%;
  color: #eee;
  float: left;
}
.admins .box .info h3 {
  font-size: 19px;
}
.admins .box .info p {
  color: #bbb;
}

/* End users */
/* Start statis */

.statis {
  color: #eee;
  margin-top: 15px;
}
.statis .box {
  position: relative;
  padding: 15px;
  overflow: hidden;
  border-radius: 3px;
  margin-bottom: 25px;
}
.statis .box h3:after {
  content: "";
  height: 2px;
  width: 70%;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.12);
  display: block;
  margin-top: 10px;
}
.statis .box i {
  position: absolute;
  height: 70px;
  width: 70px;
  font-size: 22px;
  padding: 15px;
  top: -25px;
  left: -25px;
  background-color: rgba(255, 255, 255, 0.15);
  line-height: 60px;
  text-align: right;
  border-radius: 50%;
}

/*chart*/
.chrt3 {
  padding-bottom: 50px;
}
.chrt3 .chart-container {
  height: 350px;
  padding: 15px;
  margin-top: 25px;
}
.chrt3 .box {
  padding: 15px;
}

.main-color {
  color: #ffc107;
}
.warning {
  background-color: #f0ad4e;
}
.danger {
  background-color: #d9534f;
}
.success {
  background-color: #5cb85c;
}
.inf {
  background-color: #5bc0de;
}

/* Start bootstrap */
.navbar-right .dropdown-menu {
  right: auto !important;
  left: 0 !important;
}
.navbar-default {
  background-color: #6f6486 !important;
  border: none !important;
  border-radius: 0 !important;
  margin: 0 !important;
}
.navbar-default .navbar-nav > li > a {
  color: #eee !important;
  line-height: 55px !important;
  padding: 0 10px !important;
}
.navbar-default .navbar-brand {
  color: #fff !important;
}
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #eee !important;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  background-color: transparent !important;
  color: #fff !important;
}

.navbar-default .navbar-brand {
  line-height: 55px !important;
  padding: 0 !important;
}
.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
  color: #fff !important;
}

.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
  margin: 0 !important;
}
@media (max-width: 767px) {
  .navbar > .container-fluid .navbar-brand {
    margin-left: 15px !important;
  }
  .navbar-default .navbar-nav > li > a {
    padding-left: 0 !important;
  }
  .navbar-nav {
    margin: 0 !important;
  }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border: none !important;
  }
}

.navbar-default .navbar-nav > li > a {
  float: left !important;
}
.navbar-default .navbar-nav > li > a > span:not(.caret) {
  background-color: #e74c3c !important;
  border-radius: 50% !important;
  height: 25px !important;
  width: 25px !important;
  padding: 2px !important;
  font-size: 11px !important;
  position: relative !important;
  top: -10px !important;
  right: 5px !important;
}
.dropdown-menu > li > a {
  padding-top: 5px !important;
  padding-right: 5px !important;
}
.navbar-default .navbar-nav > li > a > i {
  font-size: 18px !important;
}

/* Start media query */

@media (max-width: 767px) {
  #contents {
    margin: 0 !important;
  }
  .statistics .box {
    margin-bottom: 25px !important;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #ccc !important;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #fff !important;
  }
  .navbar-default .navbar-toggle {
    border: none !important;
    color: #eee !important;
    font-size: 18px !important;
  }
  .navbar-default .navbar-toggle:focus,
  .navbar-default .navbar-toggle:hover {
    background-color: transparent !important;
  }
}

/*  */

.remove-row-wrap {
  flex-wrap: nowrap !important;
}

.recent_active_main .activity_9 p {
  /* width: 16ch !important; */
  white-space: break-spaces;
  /* overflow: hidden;
  text-overflow: ellipsis; */
}

.activity_9 p.paragraphText {
  width: 100% !important;
  padding: 0px 10px 0px 0px !important;
  font-size: 13px !important;
}

.mm_avator_img {
  padding: 0px 13px 0px 0px;
  width: 53px;
}

.controller-row{
  display: flex;
  justify-content:flex-start;
  align-items: center;
}
.controller-row .controller-1{
  width: 10%;
}
.controller-row .controller-2{
  width: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.controller-row .controller-3{
  display: flex;
  width: 70%;
  margin-left: 14%;
}
.controller-row .controller-4{
  width: 10%;
  margin-left: 2%;
}
