.text__underline-wrapper{
text-decoration: underline;
}

.tc-status {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 4px;
}