
.mm-w-30 {
    width: 30%;
}
.mm-new-btns2 {
    width: 100%;
    display: inline-block;
    
}
.mm-new-btns2 .btn {
    margin: 0px 15px;
    background-color: transparent;
    border: 1px solid #bbb2b2;
    background-color: #e3e3e3;
}

.mm-vet-info {
    width: 100%;
    display: inline-block;
    background-color: #7db7c1;
    padding: 15px;
}
.mm-vet-info p {
    font-size: 14px;
    color: black;
}
.mm-vetinfo-main {
    margin: 29px 2px;
    padding: 13px 40px;
}
.mm-vet-info input {
    margin-right: 10px;
}
.mn-btn-submet {
    padding: 5px 10px;
    display: inline-block;
    /* background-color: blanchedalmond; */
    font-size: 14px;
    /* color: burlywood; */
    margin: 5px 15px 5px 20px;
}

.ms-btn-size{
    font-size: 13px;
    width: 114px;
}

.w-100{
    width: 100% !important;
}