.filter-panel {
  padding: 25px 15px;
}

.filter-panel .search-filters-wrapper {
  max-width: 1000px;
}

.filter-panel .search-button {
  width: 38px;
  background: #ededed;
  border: 1px solid #ced4da;
}

.filter-panel .sort-config-wrapper {
  text-align: right;
}

.sortby-controller {
  width: 250px;
}

#audit-logs-table-wrapper {
  padding: 0 15px;
}

.react-bootstrap-table thead {
  background: #2f73ad;
  color: #ffffff;
  font-weight: bold;
}

.react-datepicker__input-container:before {
  right: 10px;
}

.react-bootstrap-table-pagination-total {
  margin: 0 20px;
}
.react-bootstrap-table-page-btns-ul {
  float: right;
  margin: 0 20px;
}
.react-bootstrap-table-page-btns-ul .page-item {
  margin-left: 14px;
  border: 0 none transparent;
}
.react-bootstrap-table-page-btns-ul .page-item .page-link {
  border: 0 none transparent;
  border-radius: 50%;
  background: #e2e2e2;
}
.react-bootstrap-table-page-btns-ul .page-item.active .page-link{
  background: #2f73ad !important;
}
