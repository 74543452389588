.filter-panel {
  padding: 25px 15px;
}

.filter-panel .search-filters-wrapper {
  max-width: 1000px;
}

.filter-panel .search-button {
  width: 38px;
  background: #ededed;
  border: 1px solid #ced4da;
}

.filter-panel .sort-config-wrapper {
  text-align: right;
}

.sortby-controller {
  width: 250px;
}

#email-table-wrapper {
  padding: 0 15px;
}

.react-bootstrap-table thead {
  background: #2f73ad;
  color: #ffffff;
  font-weight: bold;
}

.react-datepicker__input-container:before {
  right: 10px;
}

.react-bootstrap-table-pagination-total {
  margin: 0 20px;
}
.react-bootstrap-table-page-btns-ul {
  float: right;
  margin: 0 20px;
}
.react-bootstrap-table-page-btns-ul .page-item {
  margin-left: 14px;
  border: 0 none transparent;
}
.react-bootstrap-table-page-btns-ul .page-item .page-link {
  border: 0 none transparent;
  border-radius: 50%;
  background: #e2e2e2;
}
.react-bootstrap-table-page-btns-ul .page-item.active .page-link{
  background: #2f73ad !important;
}
.expanded-reading-view {
  max-height: 500px;
  overflow-y: auto;
}
.email-message-view {
  margin:20px 0;
  padding: 10px 20px;
  font-size: 18px !important;
}
.email-attachments-view {
  margin: 20px 0;
}
.email-attachments-view ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.email-attachments-view ul > li {
  margin: 10px;
  padding: 5px;
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: 1px solid black;
  font-weight: bold;
}
.action-button {
  margin: 0 10px;
  outline:0;
  border: 0;
  background: transparent;
  cursor: pointer;
}
button[disabled].action-button {
  opacity: 0.3;
  cursor: default;
}