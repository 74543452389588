body,
.modal-content {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    text-shadow: none;
}

.tracking_code_popup__table tbody tr {
    box-shadow: 0px 1px 2px rgba(197, 197, 197, 0.85) !important;
    -webkit-box-shadow: 0px 1px 5px rgba(197, 197, 197, 0.85) !important;
}

.tracking_code_popup__table tbody {
    border: 1px solid rgba(197, 197, 197, 0.85)
}

.tracking_code_checks ul {
    display: flex;
    justify-content: space-between;
}

.modal__tracking_code .modal-lg {
    width: 586px !important;
}

.modal__tracking_code .modal-body {
    padding: 0;
}

/* .user_setup-poup_body input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #e9e9f0;
    font-size: 12px;
    color: #000000;
} */

.tracking_code_checks .remember_check {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    float: right;
    bottom: 3px;
    margin-left: 7px;
}

.search_dep {
    position: absolute;
    right: 0;
    bottom: 6px;
    cursor: pointer;
}

.tracking_code_popup__table thead th,
.tracking_code_popup__table tbody td {
    width: 95px;
}

.tracking_code_popup__table label {
    top: 0;
    margin-bottom: 12px;
}

.tracking_code_popup_select .custon_select-selector-inner__control {
    border-bottom: 1px solid #e9e9f0 !important;
    min-height: 25px;
}

.tracking_code_popup_select .custon_select-selector-inner__control.css-e56m7-control:after {
    right: 0px;
    color: #858585 !important;
    font-size: 14px !important;
    top: 41%;
}

.tracking_code_popup__table h2 {
    font-weight: bold;
    font-size: 18.66px;
    color: #4c4c4c;
    padding-bottom: 22px;
    margin-bottom: 0;
    padding-top: 10px;
}

.tracking_code_popup__table thead th {
    color: #fff;
    font-size: 13px;
}

.tracking_code_popup__table tbody td {
    font-size: 13px;
    color: #616060;
}

.tracking_code_popup_select .css-1wa3eu0-placeholder {
    font-size: 12px !important;
    color: #000000;
}

body,
.modal-content {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    text-shadow: none;
}

.tracking_code_popup__table tbody {
    border: 1px solid rgba(197, 197, 197, 0.85)
}

.tracking_code_checks {
    width: 100%;
    float: left;
    font-size: 13px;
    color: #616060;
    padding: 28px 20px 0px;
}

.tracking_code_checks ul {
    display: flex;
    justify-content: space-between;
}

.modal__tracking_code .modal-lg {
    width: 586px !important;
}

.modal__tracking_code .modal-body {
    padding: 0;
}

.tracking_code_checks .remember_check {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    float: right;
    bottom: 3px;
    margin-left: 7px;
}

.search_dep {
    position: absolute;
    right: 0;
    bottom: 6px;
    cursor: pointer;
}

.tracking_code_popup__table label {
    top: 0;
    margin-bottom: 12px;
}

.tracking_code_popup_select .custon_select-selector-inner__control {
    border-bottom: 1px solid #e9e9f0 !important;
    min-height: 25px;
}

.tracking_code_popup_select .custon_select-selector-inner__control.css-e56m7-control:after {
    right: 0px;
    color: #858585 !important;
    font-size: 14px !important;
    top: 41%;
}

.tracking_code_popup__table h2 {
    font-weight: bold;
    font-size: 18.66px;
    color: #4c4c4c;
    padding-bottom: 22px;
    margin-bottom: 0;
    padding-top: 10px;
}

.tracking_code_popup__table thead th {
    color: #fff;
    font-size: 13px;
}

.tracking_code_popup__table tbody td {
    font-size: 13px;
    color: #616060;
}

.tracking_code_popup_select .css-1wa3eu0-placeholder {
    font-size: 12px !important;
    color: #000000;
}

body,
.modal-content {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    text-shadow: none;
}


.tracking_code_popup__table table{
    box-shadow: 0px 5px 4px rgba(0,0,0,0.1);
}

.tracking_code_checks {
    width: 100%;
    float: left;
    font-size: 13px;
    color: #616060;
    padding: 0;
}

.tracking_code_checks ul {
    display: flex;
    justify-content: flex-start;
}

.tracking_code_checks ul li label {
    top: -2px;
}

.tracking_code_checks .track_code_admin_check label {
    float: none;
    top: -14px;
}

.modal__tracking_code .modal-lg {
    width: 486px !important;
}

.modal__tracking_code .modal-body {
    padding: 0;
}

.tracking_code_checks .remember_check {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    float: right;
    bottom: 3px;
    margin-left: 7px;
}

.search_dep {
    position: absolute;
    right: 0;
    bottom: 6px;
    cursor: pointer;
}

.tracking_code_popup__table label {
    top: 0;
    margin-bottom: 12px;
}

.tracking_code_popup_select .custon_select-selector-inner__control {
    border-bottom: 1px solid #e9e9f0 !important;
    min-height: 25px;
}

.tracking_code_popup_select .custon_select-selector-inner__control.css-e56m7-control:after {
    right: 0px;
    color: #858585 !important;
    font-size: 14px !important;
    top: 41%;
}

.tracking_code_popup__table h2 {
    font-weight: bold;
    font-size: 18.66px;
    color: #4c4c4c;
    padding-bottom: 22px;
    margin-bottom: 0;
    padding-top: 10px;
    display: inline-block;
}

.tracking_code_popup__table thead th {
    color: #fff;
    font-size: 13px;
}

.tracking_code_popup__table tbody td {
    font-size: 13px;
    color: #616060;
}

.tracking_code_popup_select .css-1wa3eu0-placeholder {
    font-size: 12px !important;
    color: #000000;
}

.tracking_code_popup__table thead th:first-child,
.tracking_code_popup__table tbody td:first-child {
    width: auto;
}

@media (min-width:1920px) and (max-width:4000px) {

    .tracking_code_popup__table thead th,
    .tracking_code_popup__table tbody td {
        width: 96px;
    }

}
@media (min-width:4000px) and (max-width:8000px) {

    .tracking_code_popup__table thead th,
    .tracking_code_popup__table tbody td {
        width: 93px;
    }

}