.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  background-image: url(./Assets/images/Preloader.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.1);
}

.transparent-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  background-image: url(./Assets/images/Preloader.gif);
  background-repeat: no-repeat;
  background-position: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#responsive_menu {
  display: none;
}

@media (min-width: 320px) and (max-width: 767px) {
  #responsive_menu {
    display: flex;

    justify-content: space-between;

    height: 71px;
    border-top: solid 1px #e6e6e6;
    padding-top: 20px;
    position: fixed;
    border-bottom: 0;
    bottom: 0;
    background: #fff;
    list-style: none;
    width: 100%;
    z-index: 999999999 !important;

    margin-bottom: 0;
    padding-left: 0px;
  }
  .responsive_menu_active span {
    color: #03599a;
    fill: #03599a;
  }
  .responsive_menu_active path {
    color: #03599a !important;
    fill: #03599a !important;
  }

  #responsive_menu li {
    line-height: 48px;
    list-style: none;
    width: 19%;
    display: inline;
    display: inline-block;
    list-style: outside none none;
  }

  #responsive_menu li a {
    display: block;
    text-decoration: none;
    color: #999999;
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
    line-height: 25px;
    display: inline-block;
    width: 100%;
  }

  #responsive_menu li a svg {
    width: 20px;
    height: 20px;
  }
  #responsive_menu li span {
    display: block;
    text-align: center;
  }

  #responsive_menu li a:hover {
    text-decoration: none;
    color: #03599a;
  }

  #responsive_menu li .cls-1 {
    fill: #949494;
  }

  #responsive_menu li a svg:hover {
    color: #03599a;
    fill: #03599a;
  }

  #responsive_menu li a:active,
  #responsive_menu li a:focus {
    text-decoration: none;
  }

  #responsive_menu li a:hover svg path,
  #responsive_menu li a:hover {
    color: #03599a;
    fill: #03599a;
  }

  #responsive_menu > .sidebar-brand {
    color: #03599a;
  }

  #responsive_menu > .sidebar-brand a {
    color: #999999;
  }

  #responsive_menu > .sidebar-brand a:hover {
    color: #03599a;
    background: none;
  }
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #30679a !important;
}

.ant-table-thead th.ant-table-column-sort {
  background: #30679a !important;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: none !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #d6d6d6 !important;
}
.theme-color-picker > input {
  padding: 0px;
  border: none;
  width: 100%;
  height: 35px;
}
/* .ant-table-row:hover {
} */
/* .ant-table-tbody > tr > td :hover {
  background: #d6d6d6 !important;
} */

iframe {
  position: relative !important;
}

.react-tooltip {
  z-index: 18000;
}