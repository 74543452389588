body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#sw-msg-01 {
  color: #fff !important;
  background-color: #43a047 !important;
  width: 350px !important;
  min-height: 50px !important;
  padding: 3px !important;
  cursor: default;
}

#sw-msg-01 .Toastify__toast-body .sw-refresh-btn {
  background: transparent;
  border: 0;
  float: right;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
}
#sw-msg-01 .Toastify__toast-body .sw-refresh-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.sw-refresh-btn:focus {
  box-shadow: none;
}
#sw-msg-01 .Toastify__toast-body {
  width: 100%;
}
#sw-msg-01 .Toastify__close-button {
  display: none;
}
