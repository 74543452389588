.modal-content {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    text-shadow: none;
}

.modal__setting .modal-lg {
    width: 488px !important;
}

.Indirecttaxcode-poup_heading {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: #ffffff;
    display: inline-block;
    line-height: 20px;
    padding-top: 5px;
}

.Indirecttaxcode-poup_can-sav-btn {
    float: right;
    text-align: right;
    line-height: 20px;
}

.Indirecttaxcode-poup_can-sav-btn .can-btn1 {
    padding: 0;
    margin: 0;
    background-color: transparent;
    color: #ffffff;
    font-size: 16px;
}

.Indirecttaxcode-poup_can-sav-btn .can-btn1 img {
    padding-right: 11px;
}

.Indirecttaxcode-poup_can-sav-btn .can-btn1 {
    padding: 0;
    margin: 0;
    background-color: transparent;
    color: #ffffff;
    font-size: 16px;
}

.setting_header {
    /* background-color: #2f73ad; */
    padding: 19px 17px 19px 28px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-left: -1px;
}

.Indirecttaxcode-poup_body {
    padding: 28px;
    padding-top: 20px;
}

.modal__setting .modal-body {
    padding: 0;
}

.Indirecttaxcode-poup_body label {
    font-size: 13px;
    color: #383838;
    margin-bottom: 0px;

}

.hide_checks {
    padding-top: 35px;
}

.hide_checks h2 {
    color: #333333;
    font-size: 18.664px;
    font-weight: bold;
    text-align: left;
}

.indirect_tax_code_select .custon_select-selector-inner__control.css-e56m7-control:after {
    right: 20px;
    color: #858585 !important;
    font-size: 14px !important;
    top: 41%;
}

.Indirecttaxcode-poup_body label {
    font-size: 13px;
    color: #616060;
}

.Indirecttaxcode-poup_body .css-1wa3eu0-placeholder {
    font-size: 13px;
    color: #000000;
}

.indirect_tax_code_select .custon_select-selector-inner__control {
    border-bottom: 1px solid #e9e9f0 !important;
}

.indirect_tax_code_select {
    padding-right: 28px;
}

.search_indirect {
    position: absolute;
    right: 29px;
    bottom: 6px;
    cursor: pointer;
}

.free_filed {
    padding-right: 28px;
}

.free_filed input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #e9e9f0;
    font-size: 13px;
    color: #000000;

}

.indirect_tax_code_table h2 {
    font-weight: bold;
    font-size: 18.66px;
    color: #4c4c4c;
    padding-bottom: 22px;
    margin-bottom: 0;
    padding-top: 10px;
}

.indirect_tax_code_table thead th {
    color: #fff;
    font-size: 13px;
}

.Indirecttaxcode-poup_body .check_main {
    margin-bottom: 11px;
}

.Indirecttaxcode-poup_body .click_checkmark {
    top: 0;
}

.indirect_tax_code_table tbody td {
    font-size: 13px;
    color: #616060;
  
}

.indirect_tax_code_table thead th:first-child,
.indirect_tax_code_table tbody td:first-child {
    width: auto;
}

.indirect_tax_code_table thead th,
.indirect_tax_code_table tbody td {
    width: 100px;
    text-transform: capitalize;
}
.indirect_tax_code_table table {
    box-shadow: 0px 5px 4px rgba(0,0,0,0.1);
}