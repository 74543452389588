.select-label-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
}

.select-label-wrapper .owner-label {
  display: inline-block;
  /*margin-right: 10px;*/
  width: 60px;
}
.select-label-wrapper .owner-label-3 {
  display: inline-block;
  /*margin-right: 10px;*/
  width: 40px;
}
.select-label-wrapper .owner-label-2{
  display: inline-block;
  width: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.reports-table {
  overflow-x: auto;
  width: 100%;
  display: inline-block;
}
.report-table{
  border-bottom: none !important;
}

.reports-table .dropdown-menu.show {
  transform: translate(-161.444px, 35.5556px) !important;
  margin-left: 0px !important;
}

.reports-table table.dataTable thead th,
.reports-table table.dataTable thead td {
  padding: 10px;
}

.report-file {
  display: inline-block;
  padding: 2px 15px;
  background-color: #f6f6f6;
  margin: 8px 0px;
}

.report-file span {
  padding: 8px 0px 8px 8px;
  cursor: pointer;
}
