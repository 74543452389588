/*
Stimulsoft.Reports.JS
Version: 2020.3.2
Build date: 2020.06.17
License: https://www.stimulsoft.com/en/licensing/reports
*/
.stiDesignerChartFormSeriesCaptionCell,
.stiDesignerChartFormShowSeriesLabelsButtons,
.stiDesignerCheckBox_Mouse {
    color: #444;
    font-size: 12px;
    font-family: Arial
}

.stiDesignerCheckBoxDisabled_Mouse,
.stiDesignerCheckBoxDisabled_Touch,
.stiDesignerCheckBoxMenuItem,
.stiDesignerCheckBoxMenuItemOver,
.stiDesignerCheckBox_Mouse,
.stiDesignerCheckBox_Touch {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerChartFormToolBarCell {
    border: 0;
    margin: 0;
    padding: 0;
    border-bottom: 1px dotted #c6c6c6
}

.stiDesignerChartFormToolBarCellWithOutBorder {
    border: 0;
    margin: 0;
    padding: 0
}

.stiDesignerSeriesPropertiesContainer {
    border-left: 1px dotted #c6c6c6;
    overflow-y: auto;
    overflow-x: hidden
}

.stiDesignerSeriesContainer {
    border-right: 1px dotted #c6c6c6;
    overflow-y: auto;
    overflow-x: auto;
    text-align: center
}

.stiDesignerChartFormContainer {
    border-top: 1px dotted #c6c6c6;
    overflow-y: auto
}

.stiDesignerChartImage {
    height: 400px;
    width: 400px;
    overflow: hidden
}

.stiDesignerChartFormSeriesCaptionCell {
    text-align: center;
    padding: 4px
}

.stiDesignerChartFormSeriesLabelsButtonsCell {
    border-top: 1px dotted #c6c6c6
}

.stiDesignerChartFormSeriesWizardContainer {
    overflow-y: auto;
    text-align: center
}

.stiDesignerChartFormProgress {
    position: absolute;
    left: 70px;
    top: 175px
}

.stiDesignerCheckBoxDisabled_Mouse {
    font-size: 12px;
    font-family: Arial;
    color: #d6d3d6
}

.stiColorFormControlsTable,
.stiDesignerCheckBoxMenuItem,
.stiDesignerCheckBoxMenuItemOver,
.stiDesignerCheckBox_Touch {
    color: #444;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerCheckBoxImageBlock_Mouse {
    width: 13px;
    height: 13px;
    border: 1px solid #ababab;
    background: #fff
}

.stiDesignerCheckBoxImageBlockOver_Mouse {
    width: 13px;
    height: 13px;
    border: 1px solid #a3bde3;
    background: #e6eefa
}

.stiDesignerCheckBoxImageBlockDisabled_Mouse {
    width: 13px;
    height: 13px;
    border: 1px solid #e1e1e1;
    background: #f7f7f7
}

.stiDesignerCheckBoxDisabled_Touch {
    font-size: 12px;
    font-family: Arial;
    color: #d6d3d6
}

.stiDesignerCheckBoxImageBlock_Touch {
    width: 16px;
    height: 16px;
    border: 1px solid #ababab;
    background: #fff
}

.stiDesignerCheckBoxImageBlockOver_Touch {
    width: 16px;
    height: 16px;
    border: 1px solid #a3bde3;
    background: #e6eefa
}

.stiDesignerCheckBoxImageBlockDisabled_Touch {
    width: 16px;
    height: 16px;
    border: 1px solid #e1e1e1;
    background: #f7f7f7
}

.stiDesignerCheckBoxMenuItemImageBlock {
    width: 18px;
    height: 18px;
    border: 1px solid #fff;
    background: 0 0
}

.stiDesignerCheckBoxMenuItemImageBlockChecked {
    width: 18px;
    height: 18px;
    border: 1px solid #fff;
    background: #d5e1f2
}

.stiDesignerCheckBoxMenuItemOver {
    background: #d5e1f2
}

.stiColorControlWithBorder {
    border: 1px solid #ababab
}

.stiColorControlWithBorderDisabled {
    border: 1px solid #e1e1e1
}

.stiColorControlImage {
    border: 1px solid #ababab;
    width: 64px;
    height: 15px;
    cursor: default
}

.stiColorControlImageWithoutBorder {
    border: 1px solid transparent;
    width: 64px;
    height: 15px;
    cursor: default
}

.stiColorControlWithImage_ColorBar {
    border: 1px solid transparent;
    width: 14px;
    height: 2px;
    cursor: default
}

.stiColorFormControlsTable {
    cursor: default
}

.stiColorFormColorBar {
    border: 1px solid #c6c6c6;
    width: 128px;
    height: 128px;
    display: inline-block
}

.stiColorFormWebColorPanel {
    border: 1px dotted #c6c6c6;
    background: #fff;
    overflow-X: hidden;
    overflow-Y: auto
}

.stiColorDialogHeader {
    background: #eee;
    font-family: Arial;
    font-size: 12px;
    color: #949494;
    cursor: default;
    padding: 7px
}

.stiDesignerComponentButtonOver_Mouse,
.stiDesignerComponentButtonSelected_Mouse,
.stiDesignerComponentButton_Mouse {
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    min-width: 55px;
    cursor: default;
    overflow: hidden;
    font-family: Arial;
    font-size: 12px
}

.stiColorDialogButton_Mouse {
    width: 13px;
    height: 13px;
    border: 1px solid #e2e4e7;
    cursor: default
}

.stiColorDialogButtonOver_Mouse {
    width: 13px;
    height: 13px;
    border: 1px solid #f29436;
    cursor: default
}

.stiColorDialogButton_Touch {
    width: 22px;
    height: 22px;
    border: 1px solid #e2e4e7;
    cursor: default
}

.stiColorDialogButtonOver_Touch {
    width: 22px;
    height: 22px;
    border: 1px solid #f29436;
    cursor: default
}

.stiDesignerComponentButton_Mouse {
    min-height: 80px
}

.stiDesignerComponentButtonOver_Mouse {
    min-height: 80px;
    background: #d5e1f2
}

.stiDesignerComponentButtonSelected_Mouse {
    min-height: 80px;
    background: #c2d5f2
}

.stiDesignerComponentButtonDisabled_Mouse {
    min-height: 80px;
    min-width: 55px;
    cursor: default;
    overflow: hidden;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerComponentButtonOver_Touch,
.stiDesignerComponentButtonSelected_Touch,
.stiDesignerComponentButton_Touch {
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    min-height: 80px;
    min-width: 55px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    overflow: hidden
}

.stiDesignerComponentButtonOver_Touch {
    background: #d5e1f2
}

.stiDesignerComponentButtonSelected_Touch {
    background: #c2d5f2
}

.stiDesignerComponentButtonDisabled_Touch {
    min-height: 80px;
    min-width: 55px;
    cursor: default;
    overflow: hidden;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerContainerItemOver_Mouse,
.stiDesignerContainerItemSelected_Mouse,
.stiDesignerContainerItem_Mouse {
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    display: inline-block;
    height: 38px;
    cursor: default;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerContainerItem_Mouse {
    border: 1px solid #ababab;
    background: #fff
}

.stiDesignerContainerItemOver_Mouse {
    border: 1px solid #a3bde3;
    background: #e6eefa
}

.stiDesignerContainerItemSelected_Mouse {
    border: 1px solid #97b2db;
    background: #c2d5f2
}

.stiDesignerContainerItemDisabled_Mouse {
    display: inline-block;
    height: 38px;
    border: 1px solid #e1e1e1;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerContainerItemOver_Touch,
.stiDesignerContainerItemSelected_Touch,
.stiDesignerContainerItem_Touch {
    color: #444;
    height: 38px;
    font-size: 12px;
    font-family: Arial;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    cursor: default;
    display: inline-block
}

.stiDesignerContainerItem_Touch {
    border: 1px solid #ababab;
    background: #fff
}

.stiDesignerContainerItemOver_Touch {
    border: 1px solid #a3bde3;
    background: #e6eefa
}

.stiDesignerContainerItemSelected_Touch {
    border: 1px solid #97b2db;
    background: #c2d5f2
}

.stiDesignerContainerItemDisabled_Touch {
    display: inline-block;
    height: 38px;
    border: 1px solid #e1e1e1;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDateControl_Mouse {
    border: 1px solid #ababab;
    color: #444;
    background: #fff
}

.stiDesignerDateControlOver_Mouse {
    border: 1px solid #a3bde3;
    color: #444;
    background: #fff
}

.stiDesignerDateControlDisabled_Mouse {
    border: 1px solid #e1e1e1;
    background: #fff
}

.stiDesignerDateControl_Touch {
    border: 1px solid #ababab;
    color: #444;
    background: #fff
}

.stiDesignerDateControlOver_Touch {
    border: 1px solid #a3bde3;
    color: #444;
    background: #fff
}

.stiDesignerDateControlDisabled_Touch {
    border: 1px solid #e1e1e1;
    background: #fff
}

.stiDesignerDateControl_TextBox_Mouse {
    font-size: 12px;
    height: 21px;
    border: 0;
    margin: 0;
    padding: 0 0 0 4px;
    font-family: Arial;
    -webkit-border-radius: 0;
    outline: 0;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, 0)));
    background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0));
    background: #fff;
    color: #444
}

.stiDesignerDateControl_TextBox_Touch,
.stiDesignerTextArea_Mouse {
    padding: 0 0 0 4px;
    outline: 0;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, 0)))
}

.stiDesignerDateControl_TextBox_Touch {
    font-size: 12px;
    height: 26px;
    border: 0;
    margin: 0;
    font-family: Arial;
    -webkit-border-radius: 0;
    background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0));
    background: #fff;
    color: #444
}

.stiDesignerDateControlDropDownListButton_Mouse {
    height: 21px;
    color: #444;
    cursor: default;
    background: #fff;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDateControlDropDownListButtonOver_Mouse {
    height: 21px;
    color: #444;
    cursor: default;
    background: #d5e1f2;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDateControlDropDownListButtonSelected_Mouse {
    height: 21px;
    color: #444;
    cursor: default;
    background: #c2d5f2;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDateControlDropDownListButtonDisabled_Mouse {
    height: 21px;
    cursor: default;
    color: #d6d3d6;
    background: #fff;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDateControlDropDownListButtonOver_Touch,
.stiDesignerDateControlDropDownListButtonSelected_Touch,
.stiDesignerDateControlDropDownListButton_Touch {
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    height: 26px;
    cursor: default
}

.stiDesignerDateControlDropDownListButton_Touch {
    background: #fff
}

.stiDesignerDateControlDropDownListButtonOver_Touch {
    background: #d5e1f2
}

.stiDesignerDateControlDropDownListButtonSelected_Touch {
    background: #c2d5f2
}

.stiDesignerDateControlDropDownListButtonDisabled_Touch {
    height: 26px;
    cursor: default;
    color: #d6d3d6;
    background: #fff;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDatePickerDayButtonDisabled_Mouse,
.stiDesignerDatePickerDayButtonOver_Mouse,
.stiDesignerDatePickerDayButtonSelected_Mouse,
.stiDesignerDatePickerDayButton_Mouse {
    height: 23px;
    width: 23px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDatePickerDayButtonOver_Mouse,
.stiDesignerDatePickerDayButtonSelected_Mouse,
.stiDesignerDatePickerDayButton_Mouse,
.stiDesignerDatePickerDayOfWeekCell {
    color: #444;
    cursor: default;
    font-size: 12px;
    font-family: Arial
}

.stiDesignerDatePickerSeparator {
    border-top: 1px solid #c6c6c6
}

.stiDesignerDatePickerDayOfWeekCell {
    white-space: nowrap;
    padding: 3px;
    text-align: center;
    font-weight: 700
}

.stiDesignerDatePickerDayButtonOver_Mouse {
    background: #d5e1f2
}

.stiDesignerDatePickerDayButtonSelected_Mouse {
    background: #c2d5f2
}

.stiDesignerDatePickerDayButtonDisabled_Mouse {
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6
}

.stiDesignerDatePickerDayButton_Touch {
    height: 25px;
    width: 25px;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDatePickerDayButtonOver_Touch {
    height: 25px;
    width: 25px;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    background: #d5e1f2;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDatePickerDayButtonSelected_Touch {
    height: 25px;
    width: 25px;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    background: #c2d5f2;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDatePickerDayButtonDisabled_Touch {
    height: 25px;
    width: 25px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDesignerStylesItemDisabled_Mouse,
.stiDesignerDesignerStylesItemDisabled_Touch,
.stiDesignerDesignerStylesItemOver_Mouse,
.stiDesignerDesignerStylesItemOver_Touch,
.stiDesignerDesignerStylesItemSelected_Mouse,
.stiDesignerDesignerStylesItemSelected_Touch,
.stiDesignerDesignerStylesItem_Mouse,
.stiDesignerDesignerStylesItem_Touch {
    height: 40px;
    cursor: default;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDesignerStylesItemSelected_Mouse {
    background: #c2d5f2
}

.stiDesignerDesignerStylesItemSelected_Touch {
    background: #c2d5f2
}

.stiDesignerDictionarySeparator {
    height: 0;
    border-bottom: 1px dotted #c6c6c6
}

.stiDesignerDictionaryItemsContainer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto
}

.stiDesignerDictionaryProgressPanel {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 5px;
    background: #fff
}

.stiDesignerDictionaryDescriptionPanel {
    position: absolute;
    height: 110px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    border-top: 1px dotted #c6c6c6;
    font-family: Arial;
    font-size: 11px;
    padding: 5px
}

.stiCreateDataHintHeaderText {
    font-family: Arial;
    font-size: 13px;
    color: #19478a;
    text-align: center;
    white-space: nowrap;
    border: 0;
    padding: 10px 3px
}

.stiCreateDataHintText {
    font-family: Arial;
    font-size: 12px;
    color: gray;
    line-height: .8;
    text-align: center;
    white-space: normal;
    border: 0;
    padding: 3px
}

.stiCreateDataHintItem {
    position: absolute;
    cursor: pointer;
    z-index: 1
}

.stiCreateDataSeparatorLine {
    border-top: 1px solid #19478a;
    height: 1px;
    opacity: .8
}

.stiDesignerDropDownListImage {
    height: 16px;
    width: 32px;
    margin: 0 3px
}

.stiDesignerDropDownListImageCell {
    border: 1px solid #ababab;
    background: #fff;
    border-right: 0;
    padding: 0;
    margin: 0;
    font-size: 0
}

.stiDesignerDropDownListImageCellOver {
    border: 1px solid #a3bde3;
    background: #fff;
    border-right: 0;
    padding: 0;
    margin: 0;
    font-size: 0
}

.stiDesignerDropDownListImageCellSelected {
    border: 1px solid #97b2db;
    background: #fff;
    border-right: 0;
    padding: 0;
    margin: 0;
    font-size: 0
}

.stiDesignerDropDownListImageCellDisabled {
    background: #fff;
    border: 1px solid #e1e1e1;
    border-right: 0;
    padding: 0;
    margin: 0;
    font-size: 0
}

.stiDesignerDropDownListButton_Mouse {
    color: #444;
    cursor: default;
    background: #fff;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    border-bottom: 1px solid #ababab;
    border-top: 1px solid #ababab;
    border-right: 1px solid #ababab
}

.stiDesignerDropDownListButtonOver_Mouse,
.stiDesignerDropDownListButtonSelected_Mouse {
    cursor: default;
    border-bottom: 1px solid #a3bde3;
    border-top: 1px solid #a3bde3;
    border-right: 1px solid #a3bde3;
    color: #444
}

.stiDesignerDropDownListButtonOver_Mouse {
    background: #d5e1f2;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDropDownListButtonSelected_Mouse {
    background: #c2d5f2;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDropDownListButtonDisabled_Mouse,
.stiDesignerDropDownListButton_Touch {
    cursor: default;
    background: #fff;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDropDownListButtonDisabled_Mouse {
    color: #d6d3d6;
    border-bottom: 1px solid #e1e1e1;
    border-top: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1
}

.stiDesignerDropDownListButton_Touch {
    color: #444;
    border-bottom: 1px solid #ababab;
    border-top: 1px solid #ababab;
    border-right: 1px solid #ababab
}

.stiDesignerDropDownListButtonOver_Touch,
.stiDesignerDropDownListButtonSelected_Touch {
    color: #444;
    border-bottom: 1px solid #a3bde3;
    border-top: 1px solid #a3bde3;
    border-right: 1px solid #a3bde3;
    cursor: default;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDropDownListButtonOver_Touch {
    background: #d5e1f2
}

.stiDesignerDropDownListButtonSelected_Touch {
    background: #c2d5f2
}

.stiDesignerDropDownListButtonDisabled_Touch {
    cursor: default;
    color: #d6d3d6;
    background: #fff;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    border-bottom: 1px solid #e1e1e1;
    border-top: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1
}

.stiDesignerFileMenuItem,
.stiDesignerFileMenuItemOver,
.stiDesignerFileMenuItemSelected {
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    padding: 14px 0 14px 25px;
    cursor: default;
    white-space: nowrap
}

.stiDesignerFileMenu {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    bottom: 0;
    background: #19478a;
    z-index: 9;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerFileMenuItemOver {
    background: #3b629b
}

.stiDesignerFileMenuItemSelected {
    background: #11315f
}

.stiDesignerFileMenuItemDisabled {
    white-space: nowrap;
    color: #6b8ab8;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    padding: 14px 0 14px 25px
}

.stiDesignerChartFilterPanel,
.stiDesignerChartFilterPanelSelected {
    margin: 4px;
    padding: 5px;
    color: #444;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerFileMenuSeparator {
    background: #3b629b;
    height: 1px
}

.stiDesignerFilterContainer {
    background: #fff;
    border-top: 1px dotted #c6c6c6;
    overflow: auto
}

.stiDesignerFilterPanelHeader {
    border-bottom: 1px solid #c6c6c6
}

.stiDesignerFilterPanel {
    border: 1px solid #c6c6c6;
    background: #fff;
    color: #444;
    font-family: Arial;
    font-size: 12px;
    margin: 4px;
    -moz-box-shadow: 0 0 7px rgba(0, 0, 0, .6);
    -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, .6);
    box-shadow: 0 0 7px rgba(0, 0, 0, .3)
}

.stiDesignerChartFilterPanel {
    border: 1px solid #c6c6c6;
    background: #fff
}

.stiDesignerChartFilterPanelSelected {
    border: 1px solid #97b2db;
    background: #e6eefa
}

.stiDesignerFormButton_Mouse {
    background: #fff;
    border: 1px solid #ababab;
    height: 23px;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerFormButtonOver_Mouse {
    background: #d5e1f2;
    border: 1px solid #a3bde3;
    height: 23px;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerFormButtonSelected_Mouse {
    background: #c2d5f2;
    border: 1px solid #97b2db;
    height: 23px;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerFormButtonDisabled_Mouse {
    background: #fff;
    border: 1px solid #e1e1e1;
    height: 23px;
    color: #d6d3d6;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerFormButtonOver_Touch,
.stiDesignerFormButtonSelected_Touch,
.stiDesignerFormButton_Touch {
    color: #444;
    height: 29px;
    font-size: 12px;
    -moz-user-select: none;
    cursor: default;
    -khtml-user-select: none;
    -webkit-user-select: none;
    font-family: Arial
}

.stiDesignerFormButton_Touch {
    background: #fff;
    border: 1px solid #ababab
}

.stiDesignerFormButtonOver_Touch {
    background: #d5e1f2;
    border: 1px solid #a3bde3
}

.stiDesignerFormButtonSelected_Touch {
    background: #c2d5f2;
    border: 1px solid #97b2db
}

.stiDesignerFormButtonDisabled_Touch {
    background: #fff;
    border: 1px solid #e1e1e1;
    color: #d6d3d6;
    height: 29px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerForm {
    position: absolute;
    border: 1px solid #19478a;
    background: #fff;
    color: #444;
    -moz-box-shadow: 0 0 7px rgba(0, 0, 0, .6);
    -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, .6);
    box-shadow: 0 0 7px rgba(0, 0, 0, .3);
    cursor: default
}

.stiDesignerFormHeader {
    background: #fff;
    font-family: Arial;
    font-size: 13px;
    font-weight: 700;
    color: #19478a;
    text-align: center;
    cursor: default;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.stiDesignerFormContainer {
    background: #fff;
    border-top: 1px dotted #c6c6c6
}

.stiDesignerFormSeparator {
    height: 0;
    border-top: 1px dotted #c6c6c6;
    margin-left: 1px;
    margin-right: 1px
}

.stiDesignerFormButtonsPanel {
    float: right
}

.stiDesignerFormBlock {
    background: #fff;
    color: #444;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerFormBlockHeader {
    color: #949494;
    font-family: Arial;
    font-size: 12px;
    background: #eee
}

.stiDesignerFormPanelArrow {
    overflow: hidden;
    position: absolute;
    top: 10px;
    width: 21px;
    height: 21px;
    left: -21px
}

.stiDesignerFormPanelArrow:after {
    overflow: hidden;
    position: absolute;
    background: #fff;
    border: 1px solid #19478a;
    left: 17px;
    width: 21px;
    height: 21px;
    content: "";
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg)
}

.stiDesignerMessagesFormDescription {
    line-height: 1.2;
    border: 0;
    margin: 0;
    padding: 20px 30px 20px 0;
    overflow: hidden;
    min-width: 350px;
    max-width: 650px;
    font-family: Arial;
    font-size: 12px;
    color: #444;
    text-align: left;
    cursor: default
}

.stiDesignerNotificationFormDescription {
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    line-height: 1.3;
    color: #888;
    width: 450px;
    padding: 10px 50px
}

.stiDesignerNotificationFormMessage {
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    line-height: 1.3;
    color: #19478a;
    width: 450px;
    padding: 10px 50px
}

.stiDesignerGroupBlock {
    padding: 1px;
    font-family: Arial;
    font-size: 11px;
    color: #444;
    cursor: default
}

.stiDesignerGroupBlockCaption {
    width: 100%;
    text-align: center;
    padding: 0 8px;
    border: 0;
    white-space: nowrap;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerGroupBlockTableCell {
    vertical-align: top;
    padding: 2px;
    border: 0
}

.stiDesignerGroupBlockContainer_Mouse {
    overflow: hidden;
    height: 75px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerGroupBlockContainerWithOutCaption_Mouse {
    overflow: hidden;
    height: 90px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerGroupBlockDownRow_Mouse {
    overflow: hidden;
    height: 15px
}

.stiDesignerGroupBlockSeparator_Mouse {
    width: 1px;
    height: 84px;
    background: #c6c6c6
}

.stiDesignerGroupBlockButton_Mouse {
    height: 15px;
    width: 15px
}

.stiDesignerGroupBlockButtonOver_Mouse {
    height: 15px;
    width: 15px;
    background: #d5e1f2
}

.stiDesignerGroupBlockButtonSelected_Mouse {
    height: 15px;
    width: 15px;
    background: #c2d5f2
}

.stiDesignerGroupBlockDownRow_Touch {
    overflow: hidden;
    height: 20px
}

.stiDesignerGroupBlockContainer_Touch {
    overflow: hidden;
    height: 75px
}

.stiDesignerGroupBlockContainerWithOutCaption_Touch {
    overflow: hidden;
    height: 90px
}

.stiDesignerGroupBlockSeparator_Touch {
    width: 1px;
    height: 84px;
    background: #c6c6c6
}

.stiDesignerGroupBlockButton_Touch {
    height: 20px;
    width: 20px
}

.stiDesignerGroupBlockButtonOver_Touch {
    height: 20px;
    width: 20px;
    background: #d5e1f2
}

.stiDesignerGroupBlockButtonSelected_Touch {
    height: 20px;
    width: 20px;
    background: #c2d5f2
}

.stiDesignerGroupPanel {
    border: 1px solid #c6c6c6;
    -webkit-padding-before: 0;
    -webkit-padding-start: 0;
    -webkit-padding-end: 0;
    -webkit-padding-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    padding: 0;
    margin: 0
}

.stiDesignerGroupPanelCaption {
    padding: 2px;
    font-family: Arial;
    font-size: 12px;
    color: #444;
    cursor: default;
    margin-left: 10px
}

.stiDesignerGroupPanelHeader_Mouse,
.stiDesignerGroupPanelHeader_Touch {
    color: #8f8f8f;
    background: #eee;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    font-family: Arial;
    font-size: 12px;
    cursor: default
}

.stiDesignerGroupPanelHeader_Mouse {
    height: 23px
}

.stiDesignerGroupPanelHeader_Touch {
    height: 28px
}

.stiDesignerCaptionControls {
    white-space: nowrap;
    padding: 0 15px 0 7px;
    border: 0;
    font-family: Arial;
    font-size: 12px;
    color: #444;
    width: 1px
}

.stiDesignerTextContainer {
    white-space: normal;
    border: 0;
    font-family: Arial;
    font-size: 12px;
    color: #444
}

.stiDesignerControlCells {
    padding: 3px 4px 3px 0;
    border: 0
}

.stiDesignerCaptionControlsBigIntervals {
    white-space: nowrap;
    padding: 0 25px 0 15px;
    border: 0;
    font-family: Arial;
    font-size: 12px;
    color: #444;
    width: 1px
}

.stiDesignerControlCellsBigIntervals {
    padding: 3px 12px 3px 0;
    border: 0
}

.stiDesignerHomePanelSeparator {
    background: #c6c6c6
}

.stiDesignerDrillDownFormCaptionControls {
    white-space: nowrap;
    min-width: 200px;
    padding: 0 8px 0 15px;
    border: 0;
    margin: 0;
    font-family: Arial;
    font-size: 12px;
    color: #444
}

.stiDesignerCaptionControlsOnPanel,
.stiDesignerCaptionControlsOnPanelDisabled {
    padding: 0 5px 0 3px;
    border: 0;
    font-family: Arial;
    font-size: 12px;
    white-space: nowrap;
    width: 1px
}

.stiDesignerCaptionControlsOnPanel {
    color: #444
}

.stiDesignerCaptionControlsOnPanelDisabled {
    color: #d6d3d6
}

.stiDesignerControlCellsOnPanel {
    padding: 0 3px 0 0;
    border: 0
}

.stiImageControlBrushPropertyForSolid {
    border: 1px solid #ababab;
    width: 30px;
    height: 14px
}

.stiImageControlBrushPropertyForNotSolid {
    border: 0;
    width: 32px;
    height: 16px
}

.stiDesignerStatusPanelSeparator {
    border: 0;
    border-left: 1px dashed #a3b5d0;
    padding: 0;
    margin: 0 3px;
    height: 35px
}

.stiDesignerVariableItemsFormControlsLeftCell {
    vertical-align: top;
    margin: 0;
    padding: 0
}

.stiDesignerVariableItemsFormControlsRightCell {
    border: 0;
    vertical-align: top;
    margin: 0;
    padding: 0
}

.stiDesignerStyleDesignerFormToolbarCell {
    border: 0;
    padding: 0;
    margin: 0;
    border-bottom: 1px dotted #c6c6c6
}

.stiDesignerStyleDesignerFormPropertiesPanel {
    border: 0;
    padding: 0;
    margin: 0
}

.styleDesignerItemsContainer {
    overflow-y: auto;
    overflow-x: hidden
}

.stiDesignerSelectDataProgress {
    position: absolute;
    left: 200px;
    top: 150px
}

.stiDesignerTextEditFormSystemVariablesInfo {
    border-top: 1px dotted #c6c6c6;
    padding: 6px 6px 0;
    font-family: Arial;
    font-size: 12px;
    color: #444
}

.stiDesignerCreateDataColumsTree {
    border-right: 1px dotted #c6c6c6;
    border-bottom: 1px dotted #c6c6c6;
    overflow: auto
}

.stiDesignerCreateDataColumsButtonsTable {
    border: 0;
    margin: 0;
    padding: 0;
    border-right: 1px dotted #c6c6c6
}

.stiDesignerCreateDataMainTable {
    border-bottom: 1px dotted #c6c6c6
}

.stiDesignerConditionsFormMainContainer {
    border-left: 1px dotted #c6c6c6;
    width: 700px;
    height: 380px
}

.stiDesignerConditionPanel {
    width: 700px;
    height: 380px;
    overflow: auto
}

.stiDesignerConditionsFormToolbarCell {
    border-top: 1px dotted #c6c6c6;
    width: 100%
}

.stiDesignerConditionsFormSampleBar {
    border: 1px solid #ababab;
    margin: 0 4px
}

.stiDesignerDataGridCell,
.stiDesignerViewDataTableCell {
    border-bottom: 1px dotted #c6c6c6;
    font-family: Arial;
    font-size: 12px;
    color: #444
}

.stiDesignerPagesContainer {
    overflow-x: auto;
    overflow-y: hidden;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0
}

.stiDesignerViewDataTableCell {
    padding: 4px 15px 4px 4px;
    border-right: 1px dotted #c6c6c6
}

.stiDesignerDataGridCell {
    padding: 4px;
    border-right: 1px dotted #c6c6c6
}

.stiDesignerTextFormatFormCustomContainer {
    border-top: 1px dotted #c6c6c6
}

.stiDesignerCrossTabContainerCell {
    border: 0;
    margin: 0;
    padding: 0;
    border-left: 1px dotted #c6c6c6;
    border-bottom: 1px dotted #c6c6c6
}

.stiDesignerrCrossTabContainerToolbar {
    border: 0;
    margin: 0;
    padding: 0;
    font-family: Arial;
    font-size: 12px;
    color: #444;
    border-bottom: 1px dotted #c6c6c6;
    width: 100%
}

.stiDesignerDescriptionForBigButton {
    white-space: nowrap;
    padding: 0 25px 0 15px;
    border: 0;
    font-family: Arial;
    font-size: 12px;
    color: #444;
    line-height: 1.7
}

.stiDesignerFileMenuPanelHeader {
    background: #fff;
    color: #444;
    text-align: center;
    cursor: default;
    display: inline-block;
    margin: 30px;
    height: 60px
}

.stiDesignerFileMenuPanelHeaderRU {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 40px
}

.stiDesignerFileMenuPanelHeaderEN {
    font-family: 'Microsoft Yi Baiti';
    font-size: 55px
}

.stiImageContainerWithBorder {
    border: 1px dashed #ababab;
    background: #f9f9f9
}

.stiIconImageContainerWithBorder {
    border: 1px dashed #ababab;
    background: #f9f9f9;
    margin: 0 0 8px 8px;
    text-align: center;
    font-size: 150pt;
    font-family: Stimulsoft;
    vertical-align: middle;
    overflow: hidden
}

.stiDesignerPageSetupFormSample {
    margin: 4px
}

.stiDesignerPageSetupFormSampleCell {
    border-right: 1px dotted #c6c6c6;
    text-align: center;
    vertical-align: top;
    width: 150px
}

.stiDragAndDropHintText {
    color: #696969;
    margin-left: 40px
}

.stiResourceContainerWithBorder {
    border: 1px dashed #ababab;
    background: #f9f9f9
}

.stiResourceInnerContainer {
    overflow: auto;
    text-align: center;
    display: inline-block
}

.stiResourceViewDataContainer {
    background: #f1f1f1;
    overflow: auto
}

.stiBarCodeFormPropertiesContainer {
    width: 430px;
    padding: 5px 20px 5px 10px
}

.stiShapeFormPropertiesContainer {
    width: 400px;
    padding: 5px 20px 5px 10px
}

.stiCloudDemoPanel {
    text-align: center;
    font-family: Arial;
    font-size: 15px;
    line-height: 1.3;
    color: #888;
    padding: 10px 0
}

.stiUsingMarker {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 20px;
    background: #fff;
    left: 10px;
    top: calc(50% - 6px)
}

.stiUsingMarker div {
    margin: 2px 0 0 2px;
    width: 8px;
    height: 8px;
    border-radius: 20px;
    background: #19478a
}

.stiUsingMarkerForBigButton {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 20px;
    background: #fff;
    bottom: 1px;
    left: calc(50% - 6px)
}

.stiUsingMarkerForBigButton div {
    margin: 2px 0 0 2px;
    width: 8px;
    height: 8px;
    border-radius: 20px;
    background: #ababab
}

.stiThemesMenuHeader {
    color: #fff;
    background: #19478a;
    padding: 7px;
    font-family: Arial;
    font-size: 12px
}

.stiCrossTabsTree {
    background: #fff;
    overflow-X: hidden;
    overflow-Y: auto;
    padding: 10px
}

.stiDataContainer {
    border: 1px dashed #c6c6c6;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    padding: 1px
}

.stiDataGrid {
    border: 1px dashed #c6c6c6;
    overflow-x: auto;
    overflow-y: hidden
}

.stiDataTransformErrorBlock {
    font-family: Arial;
    font-size: 12px;
    color: red;
    background: #fcdcdc;
    padding: 15px;
    margin-top: 6px
}

.stiStylesControlItem {
    font-family: Arial;
    font-size: 12px;
    float: left;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis
}

.stiDesignerSymbolsContainer {
    overflow: auto;
    border: 1px solid #ababab;
    padding: 2px;
    margin: 12px
}

.stiDesignerBigText {
    white-space: normal;
    font-family: Arial;
    font-size: 25px;
    color: #444;
    text-align: center
}

.stiDesignerHyperlinkButtonOver_Mouse,
.stiDesignerHyperlinkButtonSelected_Mouse,
.stiDesignerHyperlinkButton_Mouse {
    color: #5a83ad;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerHyperlinkButtonOver_Mouse {
    text-decoration: underline
}

.stiDesignerHyperlinkButtonDisabled_Mouse {
    cursor: pointer;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerHyperlinkButtonOver_Touch,
.stiDesignerHyperlinkButtonSelected_Touch,
.stiDesignerHyperlinkButton_Touch {
    color: #5a83ad;
    font-size: 12px;
    font-family: Arial;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    cursor: pointer
}

.stiDesignerHyperlinkButtonOver_Touch {
    text-decoration: underline
}

.stiDesignerHyperlinkButtonDisabled_Touch {
    color: #d6d3d6;
    cursor: pointer;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerImageGalleryItem {
    cursor: default;
    font-family: Arial;
    font-size: 11px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    color: #fff
}

.stiDesignerMenuBigItemOver_Mouse,
.stiDesignerMenuBigItemSelected_Mouse,
.stiDesignerMenuBigItem_Mouse {
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 14px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerImageGalleryItemDefault {
    background: #e3e3e3;
    border: 1px solid #ababab
}

.stiDesignerImageGalleryItemOver {
    background: #d5e1f2;
    border: 1px solid #19478a
}

.stiDesignerImageGalleryItemSelected {
    background: #c2d5f2;
    border: 1px solid #19478a
}

.stiDesignerImageGalleryItemDisabled {
    background: #fff;
    border: 1px solid #e1e1e1
}

.stiItemGalleryCaptionContainer {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 70px;
    min-width: 70px;
    padding: 1px 5px;
    white-space: nowrap;
    text-align: center
}

.stiItemGalleryCaption {
    height: 15px;
    padding: 0;
    white-space: nowrap;
    margin-top: 4px
}

.stiItemGalleryCaptionDefault {
    background: #a0a0a0
}

.stiItemGalleryCaptionOver {
    background: #19478a
}

.stiItemGalleryCaptionSelected {
    background: #11315f
}

.stiItemGalleryCaptionDisabled {
    background: #a0a0a0
}

.stiDesignerImageGalleryContainer {
    overflow-x: auto;
    overflow-y: hidden
}

.stiDesignerMenuBigItem_Mouse {
    height: 66px
}

.stiDesignerMenuBigItemOver_Mouse {
    height: 66px;
    background: #d5e1f2
}

.stiDesignerMenuBigItemSelected_Mouse {
    height: 66px;
    background: #c2d5f2
}

.stiDesignerMenuBigItemOver_Touch,
.stiDesignerMenuMiddleItemOver_Mouse {
    background: #d5e1f2
}

.stiDesignerMenuBigItemDisabled_Mouse {
    height: 66px;
    color: #d6d3d6;
    cursor: default;
    font-family: Arial;
    font-size: 14px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerMenuBigItemOver_Touch,
.stiDesignerMenuBigItemSelected_Touch,
.stiDesignerMenuBigItem_Touch {
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    height: 66px;
    cursor: default;
    font-family: Arial;
    font-size: 14px
}

.stiDesignerMenuBigItemSelected_Touch {
    background: #c2d5f2
}

.stiDesignerMenuBigItemDisabled_Touch {
    height: 66px;
    color: #d6d3d6;
    cursor: default;
    font-family: Arial;
    font-size: 14px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerMenuMiddleItemOver_Mouse,
.stiDesignerMenuMiddleItemSelected_Mouse,
.stiDesignerMenuMiddleItem_Mouse {
    color: #444;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    height: 42px;
    cursor: default
}

.stiDesignerMenuMiddleItemSelected_Mouse {
    background: #c2d5f2
}

.stiDesignerMenuMiddleItemDisabled_Mouse {
    height: 42px;
    color: #d6d3d6;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerMenuMiddleItemOver_Touch,
.stiDesignerMenuMiddleItemSelected_Touch,
.stiDesignerMenuMiddleItem_Touch {
    color: #444;
    height: 42px;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerMenuMiddleItem_Touch {
    cursor: default
}

.stiDesignerMenuMiddleItemOver_Touch {
    background: #d5e1f2;
    cursor: default
}

.stiDesignerMenuMiddleItemSelected_Touch {
    background: #c2d5f2;
    cursor: default
}

.stiDesignerInnerContainerOpacityMenu,
.stiDesignerMenu {
    border: 1px solid #c6c6c6;
    background: #fff;
    cursor: default
}

.stiDesignerMenuMiddleItemDisabled_Touch {
    height: 42px;
    color: #d6d3d6;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerParentMenu {
    z-index: 35;
    position: absolute
}

.stiDesignerMenu {
    position: absolute;
    padding: 1px;
    color: #444;
    -moz-box-shadow: 0 0 7px rgba(0, 0, 0, .6);
    -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, .6);
    box-shadow: 0 0 7px rgba(0, 0, 0, .3)
}

.stiDesignerCheckPopupPanel,
.stiDesignerInnerContainerOpacityMenu,
.stiDesignerPropertiesPanelFixedMode {
    -moz-box-shadow: 0 0 7px rgba(0, 0, 0, .6);
    -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, .6)
}

.stiDesignerOpacityMenu {
    overflow: visible;
    padding: 1px
}

.stiDesignerInnerContainerOpacityMenu {
    color: #444;
    box-shadow: 0 0 7px rgba(0, 0, 0, .3)
}

.stiDesignerAboutPanel,
.stiDesignerProcessImage {
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, .6);
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .6)
}

.stiDesignerMenuHeader {
    white-space: nowrap;
    background: #eee;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    padding: 3px;
    text-align: center
}

.stiDesignerVerticalMenuSeparator {
    height: 1px;
    background: #c6c6c6
}

.stiDesignerMenuStandartItem_Mouse {
    height: 24px;
    white-space: nowrap;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerMenuStandartItemOver_Mouse {
    height: 24px;
    white-space: nowrap;
    background: #d5e1f2;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerMenuStandartItemSelected_Mouse {
    height: 24px;
    white-space: nowrap;
    background: #c2d5f2;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerMenuStandartItemDisabled_Mouse {
    height: 24px;
    white-space: nowrap;
    color: #d6d3d6;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerMenuStandartItemOver_Touch,
.stiDesignerMenuStandartItemSelected_Touch,
.stiDesignerMenuStandartItem_Touch {
    color: #444;
    font-family: Arial;
    font-size: 12px;
    height: 30px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    cursor: default;
    white-space: nowrap
}

.stiDesignerMenuStandartItemOver_Touch {
    background: #d5e1f2
}

.stiDesignerMenuStandartItemSelected_Touch {
    background: #c2d5f2
}

.stiDesignerMenuStandartItemDisabled_Touch {
    height: 30px;
    white-space: nowrap;
    color: #d6d3d6;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerStatusPanel,
.stiDesignerToolBar,
.stiDesignerToolbox {
    color: #fff;
    font-size: 12px;
    font-family: Arial
}

.stiDesignerClearAllStyles {
    padding: 0;
    margin: 0;
    border: 0
}

.stiDesignerMainPanel {
    overflow: visible;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
    background: #fff;
    cursor: default
}

.stiDesignerMainPanel>div,
.stiDesignerMainPanel>table,
.stiDesignerMainPanel>td,
.stiDesignerMainPanel>tr {
    line-height: 1;
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    padding: 0;
    margin: 0
}

.stiDesignerMainPanel *,
.stiDesignerMainPanel :after,
.stiDesignerMainPanel :before {
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box
}

.stiDesignerToolBar {
    background: #19478a;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerToolbox,
.stiDesignerWorkPanel {
    background: #fff;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerToolbox {
    position: absolute;
    overflow: hidden;
    border-right: 1px solid #c6c6c6
}

.stiDesignerWorkPanel {
    border-bottom: 1px solid #c6c6c6;
    position: relative
}

.stiDesignerStatusPanel {
    position: absolute;
    height: 35px;
    left: 0;
    bottom: 0;
    right: 0;
    background: #19478a;
    z-index: 3;
    cursor: default;
    white-space: nowrap;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPagesPanel {
    position: absolute;
    border-bottom: 1px solid #c6c6c6;
    background: #fff;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerDisabledPanel {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: #fff;
    filter: alpha(Opacity=10);
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0
}

.stiDesignerAboutPanel {
    width: 520px;
    z-index: 500;
    text-align: center;
    position: absolute;
    background: #fff;
    border: 1px solid #19478a;
    box-shadow: 0 0 7px rgba(0, 0, 0, .3)
}

.stiDesignerProcessImage,
.stiDesignerProcessImageCloud {
    z-index: 45;
    position: absolute
}

.stiDesignerAboutPanelHeader {
    width: 100%;
    font-family: Arial;
    font-size: 24px;
    text-align: center;
    color: #19478a;
    margin-top: 35px
}

.stiDesignerAboutPanelCopyright,
.stiDesignerAboutPanelVersion {
    color: #444;
    width: 100%;
    font-family: Arial;
    font-size: 12px;
    text-align: center
}

.stiDesignerAboutPanelCopyright {
    margin-top: 30px
}

.stiDesignerAboutPanelVersion {
    margin-top: 20px
}

.stiDesignerAboutPanelStiLink {
    cursor: pointer;
    width: 100%;
    text-align: center;
    font-family: Arial;
    font-size: 12px;
    margin: 10px 0 12px;
    color: #19478a;
    text-decoration: underline
}

.stiDesignerProcessImageStatusPanel {
    font-family: Arial;
    color: #fff
}

.stiDesignerProcessImage {
    border: 1px solid #2b579a;
    background: #fff;
    font-family: Arial;
    font-size: 12px;
    color: #2b579a;
    box-shadow: 0 0 7px rgba(0, 0, 0, .4)
}

.stiDesignerPaintPanel {
    position: absolute;
    overflow: auto;
    background: #f1f1f1;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPaintPanel table,
.stiDesignerPaintPanel td {
    padding: 0;
    margin: 0
}

.stiDesignerNewReportPanel {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 150px;
    right: 0
}

.stiDesignerItemsContainer {
    border: 1px dotted #c6c6c6;
    overflow-y: auto;
    overflow-x: hidden
}

.stiDesignerVariableItemsFormContainer {
    border-right: 1px dotted #c6c6c6;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 4px
}

.stiDesignerDataSourceFormColumnsAndParametersTree {
    border-right: 1px dotted #c6c6c6;
    overflow: auto;
    padding: 4px
}

.stiDesignerRelationFormColumnsContainer {
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #c6c6c6;
    padding-top: 4px;
    margin-top: 6px
}

.stiDesignerRelationFormErrorBlock {
    color: red;
    font-family: Arial;
    font-size: 12px;
    background: #fcdcdc
}

.stiDesignerInfoPanel {
    height: 30px;
    padding-left: 30px;
    position: relative;
    background: #fbf4e1;
    overflow: hidden;
    white-space: nowrap;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerHomePanel_Mouse,
.stiDesignerInsertPanel_Mouse,
.stiDesignerLayoutPanel_Mouse {
    background: #fff;
    overflow-Y: hidden;
    overflow-X: auto;
    height: 92px
}

.stiDesignerPagePanel_Mouse {
    background: #fff;
    height: 92px;
    position: relative
}

.stiDesignerPreviewPanel_Mouse {
    background: #fff;
    overflow-Y: hidden;
    overflow-X: auto;
    height: 92px
}

.stiDesignerHomePanel_Touch,
.stiDesignerInsertPanel_Touch,
.stiDesignerLayoutPanel_Touch {
    background: #fff;
    overflow-Y: hidden;
    overflow-X: auto;
    height: 97px
}

.stiDesignerPagePanel_Touch {
    background: #fff;
    height: 97px;
    position: relative
}

.stiDesignerPreviewPanel_Touch {
    background: #fff;
    overflow-Y: hidden;
    overflow-X: auto;
    height: 97px
}

.stiDesignerGroupHeaderFormPanel {
    height: 350px;
    width: 450px;
    overflow: auto;
    margin: 0;
    padding: 0;
    border: 0;
    border-right: 1px dotted #c6c6c6
}

.stiDesignerEditFormPanel,
.stiDesignerInteractionFormPanel,
.stiDesignerMapsEditorFormPanel,
.stiDesignerSubReportFormPanel,
.stiDesignerTextEditorFormPanel {
    border-right: 1px dotted #c6c6c6;
    overflow: auto
}

.stiDesignerEditFormPanel {
    height: 450px;
    width: 450px
}

.stiDesignerSubReportFormPanel {
    height: 450px;
    width: 600px
}

.stiDesignerTextEditorFormPanel {
    height: 450px;
    width: 550px
}

.stiDesignerMapsEditorFormPanel {
    height: 570px;
    width: 600px
}

.stiDesignerInteractionFormPanel {
    width: 550px
}

.stiDesignerPageSetupFormPanel {
    margin: 0;
    padding: 0;
    border: 0;
    border-right: 1px dotted #c6c6c6
}

.stiDesignerGroupHeaderFormMainPanel,
.stiDesignerImageFormMainPanel {
    padding: 0;
    border: 0;
    font-family: Arial;
    font-size: 12px;
    color: #444;
    margin: 0
}

.stiDesignerGroupHeaderFormMainPanel {
    border-bottom: 1px dotted #c6c6c6
}

.stiDesignerDataColumnFormMainPanel {
    font-family: Arial;
    font-size: 12px;
    color: #444;
    width: 400px;
    height: 450px;
    padding: 4px;
    overflow: auto
}

.stiDesignerNewReportButtonDisabled_Mouse,
.stiDesignerNewReportButtonOver_Mouse,
.stiDesignerNewReportButtonSelected_Mouse,
.stiDesignerNewReportButton_Mouse {
    height: 245px;
    width: 175px;
    cursor: default;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerGlobalizatonStringsFormContainer {
    margin: 0;
    padding: 0;
    border: 0;
    border-right: 1px dotted #c6c6c6;
    border-left: 1px dotted #c6c6c6
}

::selection {
    background: #d5e1f2
}

::-moz-selection {
    background: #d5e1f2
}

.stiDesignerNewReportButton_Mouse {
    overflow: hidden;
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerNewReportButtonOver_Mouse {
    overflow: hidden;
    background: #d5e1f2;
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerNewReportButtonSelected_Mouse {
    background: #c2d5f2;
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerNewReportButtonDisabled_Mouse {
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerNewReportButtonOver_Touch,
.stiDesignerNewReportButtonSelected_Touch,
.stiDesignerNewReportButton_Touch {
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    height: 245px;
    width: 175px;
    cursor: default;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerNewReportButtonOver_Touch {
    background: #d5e1f2
}

.stiDesignerNewReportButtonSelected_Touch {
    background: #c2d5f2
}

.stiDesignerNewReportButtonDisabled_Touch {
    height: 245px;
    width: 175px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPagesButtonOver_Mouse,
.stiDesignerPagesButton_Mouse {
    height: 26px;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPagesButton_Mouse {
    border-bottom: 2px solid #fff;
    border-top: 2px solid #fff
}

.stiDesignerPagesButtonOver_Mouse {
    border-bottom: 2px solid #d5e1f2;
    border-top: 2px solid #d5e1f2;
    background: #d5e1f2
}

.stiDesignerPagesButtonSelected_Mouse {
    border-top: 2px solid #19478a;
    border-bottom: 2px solid #fff;
    height: 26px;
    color: #19478a;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPagesButtonDisabled_Mouse,
.stiDesignerPagesButton_Touch {
    border-bottom: 2px solid #fff;
    border-top: 2px solid #fff;
    height: 26px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPagesButtonDisabled_Mouse {
    color: #d6d3d6
}

.stiDesignerPagesButton_Touch {
    color: #444
}

.stiDesignerPagesButtonOver_Touch {
    border-bottom: 2px solid #d5e1f2;
    border-top: 2px solid #d5e1f2;
    background: #d5e1f2;
    height: 26px;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPagesButtonDisabled_Touch,
.stiDesignerPagesButtonSelected_Touch {
    border-bottom: 2px solid #fff;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    height: 26px
}

.stiDesignerPagesButtonSelected_Touch {
    border-top: 2px solid #19478a;
    color: #19478a
}

.stiDesignerPagesButtonDisabled_Touch {
    border-top: 2px solid #fff;
    color: #d6d3d6
}

.stiDesignerPinButton_Mouse {
    height: 17px;
    color: #444;
    border: 1px solid transparent;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPinButtonOver_Mouse,
.stiDesignerPinButtonSelected_Mouse {
    border: 1px solid #19478a;
    color: #444;
    height: 17px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPinButtonOver_Mouse {
    background: #d5e1f2
}

.stiDesignerPinButtonSelected_Mouse {
    background: #c2d5f2
}

.stiDesignerPinButtonDisabled_Mouse {
    height: 17px;
    border: 1px solid transparent;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPinButton_Touch {
    height: 22px;
    border: 1px solid transparent;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPinButtonOver_Touch,
.stiDesignerPinButtonSelected_Touch {
    border: 1px solid #19478a;
    color: #444;
    height: 22px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPinButtonOver_Touch {
    background: #d5e1f2
}

.stiDesignerPinButtonSelected_Touch {
    background: #c2d5f2
}

.stiDesignerPinButtonDisabled_Touch {
    height: 22px;
    border: 1px solid transparent;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.mobile_designer_loader,
.mobile_designer_loader_mini_color,
.mobile_designer_loader_mini_white {
    font-size: 10px;
    position: relative;
    text-indent: -9999em
}

.mobile_designer_loader {
    margin: 60px auto;
    border-top: 6px solid rgba(25, 71, 138, .2);
    border-right: 6px solid rgba(25, 71, 138, .2);
    border-bottom: 6px solid rgba(25, 71, 138, .2);
    border-left: 6px solid #19478a;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear
}

.mobile_designer_loader,
.mobile_designer_loader:after {
    border-radius: 50%;
    width: 64px;
    height: 64px
}

.mobile_designer_loader_mini_color,
.mobile_designer_loader_mini_color:after,
.mobile_designer_loader_mini_white,
.mobile_designer_loader_mini_white:after {
    border-radius: 50%;
    width: 12px;
    height: 12px
}

.mobile_designer_loader_mini_white {
    border-top: 3px solid rgba(255, 255, 255, .2);
    border-right: 3px solid rgba(255, 255, 255, .2);
    border-bottom: 3px solid rgba(255, 255, 255, .2);
    border-left: 3px solid #fff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear
}

.mobile_designer_loader_mini_color {
    border-top: 3px solid rgba(25, 71, 138, .2);
    border-right: 3px solid rgba(25, 71, 138, .2);
    border-bottom: 3px solid rgba(25, 71, 138, .2);
    border-left: 3px solid #19478a;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.stiProgressText {
    position: absolute;
    top: 88px;
    left: 0;
    height: 40px;
    width: 100%;
    font-family: Arial;
    font-size: 20px;
    text-align: center;
    color: #444
}

.stiDesignerPropertiesBrushControlButton_Mouse {
    border: 1px solid #ababab;
    background: #fff;
    height: 20px;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertiesBrushControlButtonOver_Mouse,
.stiDesignerPropertiesBrushControlButtonSelected_Mouse {
    border: 1px solid #a3bde3;
    color: #444;
    height: 20px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertiesBrushControlButtonOver_Mouse {
    background: #d5e1f2
}

.stiDesignerPropertiesBrushControlButtonSelected_Mouse {
    background: #c2d5f2
}

.stiDesignerPropertiesBrushControlButtonDisabled_Mouse {
    border: 1px solid #e1e1e1;
    height: 20px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    background: #fff;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertiesBrushControlButton_Touch {
    border: 1px solid #ababab;
    background: #fff;
    height: 24px;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertiesBrushControlButtonOver_Touch,
.stiDesignerPropertiesBrushControlButtonSelected_Touch {
    border: 1px solid #a3bde3;
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    height: 24px;
    cursor: default;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerPropertiesBrushControlButtonOver_Touch {
    background: #d5e1f2
}

.stiDesignerPropertiesBrushControlButtonSelected_Touch {
    background: #c2d5f2
}

.stiDesignerPropertiesBrushControlButtonDisabled_Touch {
    border: 1px solid #e1e1e1;
    height: 24px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    background: #fff;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertiesPanelFooterTabButtonOver_Mouse,
.stiDesignerPropertiesPanelFooterTabButton_Mouse {
    border-right: 1px solid #fff;
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    border-left: 1px solid #fff;
    height: 28px;
    cursor: default;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerPropertiesPanelFooterTabButton_Mouse {
    border-bottom: 2px solid #fff
}

.stiDesignerPropertiesPanelFooterTabButtonOver_Mouse {
    border-bottom: 2px solid #d5e1f2;
    background: #d5e1f2
}

.stiDesignerPropertiesPanelFooterTabButtonSelected_Mouse {
    border-left: 1px solid #c6c6c6;
    border-right: 1px solid #c6c6c6;
    border-bottom: 2px solid #19478a;
    height: 28px;
    color: #19478a;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertiesPanelFooterTabButtonDisabled_Mouse {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    border-bottom: 2px solid #fff;
    height: 28px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertiesPanelFooterTabButtonOver_Touch,
.stiDesignerPropertiesPanelFooterTabButton_Touch {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    height: 28px;
    cursor: default;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerPropertiesPanelFooterTabButton_Touch {
    border-bottom: 2px solid #fff
}

.stiDesignerPropertiesPanelFooterTabButtonOver_Touch {
    border-bottom: 2px solid #d5e1f2;
    background: #d5e1f2
}

.stiDesignerPropertiesPanelFooterTabButtonSelected_Touch {
    border-left: 1px solid #c6c6c6;
    border-right: 1px solid #c6c6c6;
    border-bottom: 2px solid #19478a;
    height: 28px;
    color: #19478a;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertiesPanelFooterTabButtonDisabled_Touch {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    border-bottom: 2px solid #fff;
    height: 28px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertiesGroupHeaderButtonOver_Mouse,
.stiDesignerPropertiesGroupHeaderButtonSelected_Mouse,
.stiDesignerPropertiesGroupHeaderButton_Mouse {
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    font-weight: 700;
    height: 23px;
    cursor: default;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerPropertiesGroup {
    background: #f8f8f8
}

.stiDesignerPropertiesGroupContainer {
    background: #f8f8f8;
    padding: 5px 0
}

.stiDesignerPropertiesGroupHeaderButton_Mouse {
    background: #d5e1f2
}

.stiDesignerPropertiesGroupHeaderButtonOver_Mouse {
    background: #c2d5f2
}

.stiDesignerPropertiesGroupHeaderButtonSelected_Mouse {
    background: #97b2db
}

.stiDesignerPropertiesGroupHeaderButtonDisabled_Mouse {
    font-weight: 700;
    height: 23px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    background: #fff;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertiesGroupHeaderButtonOver_Touch,
.stiDesignerPropertiesGroupHeaderButtonSelected_Touch,
.stiDesignerPropertiesGroupHeaderButton_Touch {
    color: #444;
    height: 28px;
    font-size: 12px;
    cursor: default;
    font-family: Arial;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    font-weight: 700
}

.stiDesignerPropertiesGroupHeaderButton_Touch {
    background: #d5e1f2
}

.stiDesignerPropertiesGroupHeaderButtonOver_Touch {
    background: #c2d5f2
}

.stiDesignerPropertiesGroupHeaderButtonSelected_Touch {
    background: #97b2db
}

.stiDesignerPropertiesGroupHeaderButtonDisabled_Touch {
    font-weight: 700;
    height: 28px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    background: #fff;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertiesPanel {
    position: absolute;
    background: #fff;
    border-right: 1px solid #c6c6c6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertiesPanelRight {
    position: absolute;
    background: #fff;
    border-left: 1px solid #c6c6c6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertiesPanelFixedMode {
    position: absolute;
    background: #fff;
    border: 1px solid #19478a;
    box-shadow: 0 0 7px rgba(0, 0, 0, .3)
}

.stiDesignerDisabledPanelOfPropertiesPanel {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: #fff;
    filter: alpha(Opacity=80);
    opacity: .8;
    -moz-opacity: .8;
    -khtml-opacity: .8;
    z-index: 50
}

.stiDesignerPropertiesPanelHeader {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    background: #fff;
    border-bottom: 1px dotted #c6c6c6;
    color: #19478a;
    cursor: default;
    font-family: Arial;
    font-size: 13px;
    font-weight: 700;
    overflow: hidden;
    height: 34px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertiesPanelFooter {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 33px;
    background: #fff;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    overflow: hidden;
    border-top: 1px solid #c6c6c6
}

.stiDesignerPropertyButtonDisabled_Mouse,
.stiDesignerPropertyButtonOver_Mouse,
.stiDesignerPropertyButtonSelected_Mouse,
.stiDesignerPropertyButton_Mouse {
    height: 20px;
    cursor: default;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerPropertiesPanelInnerContent {
    position: absolute;
    overflow: auto;
    right: 0;
    left: 0;
    bottom: 34px;
    background: #fff
}

.stiDesignerPropertiesPanelShowButtonsPanel {
    position: absolute;
    width: 1px
}

.stiDesignerPropertyCaption {
    line-height: normal;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 5px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerComponentsListPanel {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    padding: 0 0 5px 6px;
    overflow: hidden;
    border-bottom: 1px dotted #c6c6c6
}

.stiDesignerPropertyButton_Mouse {
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertyButtonOver_Mouse {
    color: #444;
    background: #d5e1f2;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertyButtonSelected_Mouse {
    color: #444;
    background: #c2d5f2;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertyButtonDisabled_Mouse {
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertyButtonOver_Touch,
.stiDesignerPropertyButtonSelected_Touch,
.stiDesignerPropertyButton_Touch {
    color: #444;
    height: 25px;
    font-size: 12px;
    font-family: Arial;
    cursor: default;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerPropertyButtonOver_Touch {
    background: #d5e1f2
}

.stiDesignerPropertyButtonSelected_Touch {
    background: #c2d5f2
}

.stiDesignerPropertyButtonDisabled_Touch {
    height: 25px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiMobileDesignerRadioButton {
    border: 0;
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-family: Arial;
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiMobileDesignerRadioButtonDisabled {
    border: 0;
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-family: Arial;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiMobileDesignerRadioButtonOutCircle {
    border: 1px solid #ababab;
    background: #fff;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px
}

.stiMobileDesignerRadioButtonOutCircleOver {
    border: 1px solid #a3bde3;
    background: #e6eefa;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px
}

.stiMobileDesignerRadioButtonOutCircleDisabled {
    border: 1px solid #e1e1e1;
    background: #f7f7f7;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px
}

.stiMobileDesignerRadioButtonInnerCircle_Mouse {
    margin: 3px;
    width: 7px;
    height: 7px;
    border: 1px solid #ababab;
    background: #ababab;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px
}

.stiMobileDesignerRadioButtonInnerCircle_Touch {
    margin: 4px;
    width: 9px;
    height: 9px;
    border: 1px solid #ababab;
    background: #ababab;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px
}

.stiDesignerCheckPanel,
.stiDesignerCheckPopupPanel {
    font-family: Arial;
    font-size: 12px;
    border: 1px solid #19478a;
    background: #fff;
    position: absolute;
    z-index: 100
}

.stiDesignerCheckPanel {
    color: #444;
    cursor: default
}

.stiDesignerCheckPopupPanel {
    box-shadow: 0 0 7px rgba(0, 0, 0, .3);
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    min-width: 250px;
    max-width: 450px;
    cursor: pointer
}

.stiDesignerCheckPreviewPanel,
.stiDesignerToolTip,
.stiDesignerTreeItemForDragDrop_Mouse {
    -moz-box-shadow: 0 0 7px rgba(0, 0, 0, .6);
    -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, .6)
}

.stiDesignerCheckPopupPanelArrow {
    overflow: hidden;
    position: absolute;
    width: 31px;
    height: 21px;
    left: 11px
}

.stiDesignerCheckPopupPanelArrow:after {
    overflow: hidden;
    position: absolute;
    background: #fff;
    border: 1px solid #19478a;
    left: 7px;
    top: -17px;
    width: 21px;
    height: 21px;
    content: "";
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg)
}

.stiDesignerCheckPreviewPanel {
    position: absolute;
    background: #fff;
    box-shadow: 0 0 7px rgba(0, 0, 0, .3);
    cursor: default;
    z-index: 100
}

.stiDesignerCheckContainer {
    width: 600px;
    height: 400px;
    overflow: auto;
    position: relative
}

.stiDesignerCheckContainerItem {
    border-bottom: 1px dashed #c6c6c6;
    background: #fff;
    color: #444;
    margin: 15px 15px 0;
    font-family: Arial;
    font-size: 12px;
    line-height: 1.2
}

.stiDesignerSortPanel,
.stiDesignerSortPanelSelected {
    font-family: Arial;
    font-size: 12px;
    margin: 4px;
    color: #444
}

.stiDesignerSortContainer {
    background: #fff;
    border-top: 1px dotted #c6c6c6;
    overflow: auto;
    padding: 2px
}

.stiDesignerSortPanel {
    border: 1px solid #c6c6c6;
    background: #fff
}

.stiDesignerSortPanelSelected {
    border: 1px solid #97b2db;
    background: #e6eefa
}

.stiDesignerStandartBigButtonDisabled_Mouse,
.stiDesignerStandartBigButtonOver_Mouse,
.stiDesignerStandartBigButtonSelected_Mouse,
.stiDesignerStandartBigButton_Mouse {
    min-height: 69px;
    min-width: 50px;
    cursor: default;
    overflow: hidden;
    font-family: Arial;
    font-size: 11.5px
}

.stiDesignerStandartBigButton_Mouse {
    background: 0 0;
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerStandartBigButtonOver_Mouse {
    background: #d5e1f2;
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerStandartBigButtonSelected_Mouse {
    background: #c2d5f2;
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerStandartBigButtonDisabled_Mouse {
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerStandartBigButtonOver_Touch,
.stiDesignerStandartBigButtonSelected_Touch,
.stiDesignerStandartBigButton_Touch {
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    min-height: 69px;
    min-width: 50px;
    overflow: hidden;
    cursor: default;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerStandartBigButton_Touch {
    background: 0 0
}

.stiDesignerStandartBigButtonOver_Touch {
    background: #d5e1f2
}

.stiDesignerStandartBigButtonSelected_Touch {
    background: #c2d5f2
}

.stiDesignerStandartBigButtonDisabled_Touch {
    min-height: 69px;
    min-width: 50px;
    overflow: hidden;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerStandartSmallButtonOver_Mouse,
.stiDesignerStandartSmallButtonSelected_Mouse,
.stiDesignerStandartSmallButton_Mouse {
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    height: 23px;
    cursor: default;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerStandartSmallButtonOver_Mouse {
    background: #d5e1f2
}

.stiDesignerStandartSmallButtonSelected_Mouse {
    background: #c2d5f2
}

.stiDesignerStandartSmallButtonDisabled_Mouse {
    height: 23px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerStandartSmallButtonOver_Touch,
.stiDesignerStandartSmallButtonSelected_Touch,
.stiDesignerStandartSmallButton_Touch {
    color: #444;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    height: 28px;
    cursor: default;
    font-family: Arial;
    font-size: 12px
}

.stiDesignerStandartSmallButtonOver_Touch {
    background: #d5e1f2
}

.stiDesignerStandartSmallButtonSelected_Touch {
    background: #c2d5f2
}

.stiDesignerStatusPanelButtonOver_Mouse,
.stiDesignerStatusPanelButtonOver_Touch {
    background: #3b629b
}

.stiDesignerStandartSmallButtonDisabled_Touch {
    height: 28px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerStatusPanelButtonOver_Mouse,
.stiDesignerStatusPanelButtonSelected_Mouse,
.stiDesignerStatusPanelButton_Mouse {
    color: #fff;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    height: 35px
}

.stiDesignerStatusPanelButtonSelected_Mouse {
    background: #11315f
}

.stiDesignerStatusPanelButtonDisabled_Mouse {
    height: 35px;
    color: #6b8ab8;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerStatusPanelButtonOver_Touch,
.stiDesignerStatusPanelButtonSelected_Touch,
.stiDesignerStatusPanelButton_Touch {
    color: #fff;
    height: 35px;
    font-size: 12px;
    font-family: Arial;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    cursor: default
}

.stiDesignerStatusPanelButtonSelected_Touch {
    background: #11315f
}

.stiDesignerStatusPanelButtonDisabled_Touch {
    height: 35px;
    color: #6b8ab8;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerTabbedPane,
.stiDesignerTabsPanel {
    background: #fff
}

.stiDesignerTabbedPaneContainer {
    background: #fff;
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff
}

.stiDesignerTabsCell,
.stiDesignerTabsSelectedCell {
    border: 0;
    border-bottom: 1px solid #fff;
    padding: 0;
    margin: 0
}

.stiDesignerTabbedPaneSeparator {
    height: 15px;
    width: 1px;
    background: #c6c6c6
}

.stiDesignerStandartTabHeader {
    background: #c2d5f2
}

.stiDesignerStandartTab_Mouse {
    white-space: nowrap;
    background: #fff;
    border: 1px solid #fff;
    border-bottom: 0;
    color: #444;
    font-family: Arial;
    font-size: 13px;
    font-weight: 700;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    cursor: pointer
}

.stiDesignerStandartTabOver_Mouse,
.stiDesignerStandartTabSelected_Mouse {
    white-space: nowrap;
    color: #19478a;
    background: #fff;
    border: 1px solid #fff;
    border-bottom: 0;
    font-family: Arial;
    font-size: 13px;
    font-weight: 700;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    cursor: pointer
}

.stiDesignerStandartTabDisabled_Mouse,
.stiDesignerStandartTabOver_Touch,
.stiDesignerStandartTabSelected_Touch,
.stiDesignerStandartTab_Touch {
    border: 1px solid #fff;
    white-space: nowrap;
    background: #fff;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
    font-family: Arial;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerStandartTabDisabled_Mouse {
    border-bottom: 0;
    color: #d6d3d6
}

.stiDesignerStandartTab_Touch {
    border-bottom: 0;
    color: #444;
    padding: 5px 6px
}

.stiDesignerStandartTabOver_Touch,
.stiDesignerStandartTabSelected_Touch {
    color: #19478a;
    border-bottom: 0;
    padding: 5px 6px
}

.stiDesignerStandartTabDisabled_Touch {
    white-space: nowrap;
    background: #fff;
    border: 1px solid #fff;
    border-bottom: 0;
    color: #d6d3d6;
    font-family: Arial;
    font-size: 13px;
    font-weight: 700;
    padding: 5px 6px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    cursor: pointer
}

.stiDesignerTextAreaOver_Mouse,
.stiDesignerTextArea_Mouse {
    background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0))
}

.stiDesignerTextArea_Mouse {
    font-size: 12px;
    border: 1px solid #ababab;
    margin: 0;
    font-family: Arial;
    -webkit-border-radius: 0;
    background: #fff;
    color: #444;
    resize: none
}

.stiDesignerTextAreaDisabled_Mouse,
.stiDesignerTextAreaOver_Mouse {
    font-size: 12px;
    margin: 0;
    padding: 0 0 0 4px;
    font-family: Arial;
    outline: 0;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, 0)))
}

.stiDesignerTextAreaOver_Mouse {
    border: 1px solid #a3bde3;
    -webkit-border-radius: 0;
    background: #fff;
    color: #444;
    resize: none
}

.stiDesignerTextAreaDisabled_Mouse,
.stiDesignerTextArea_Touch {
    background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0))
}

.stiDesignerTextAreaDisabled_Mouse {
    border: 1px solid #e1e1e1;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -webkit-border-radius: 0;
    background: #f7f7f7;
    resize: none;
    color: #d6d3d6
}

.stiDesignerTextAreaOver_Touch,
.stiDesignerTextArea_Touch {
    font-size: 12px;
    margin: 0;
    padding: 0 0 0 4px;
    font-family: Arial;
    outline: 0;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, 0)));
    resize: none
}

.stiDesignerTextArea_Touch {
    border: 1px solid #ababab;
    -webkit-border-radius: 0;
    background: #fff;
    color: #444
}

.stiDesignerTextAreaOver_Touch {
    border: 1px solid #a3bde3;
    -webkit-border-radius: 0;
    background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0));
    background: #fff
}

.stiDesignerTextAreaDisabled_Touch,
.stiDesignerTextBoxEnumerator_TextBox_Mouse {
    padding: 0 0 0 4px;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, 0)));
    background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0));
    outline: 0;
    font-family: Arial;
    font-size: 12px;
    margin: 0
}

.stiDesignerTextAreaDisabled_Touch {
    border: 1px solid #e1e1e1;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -webkit-border-radius: 0;
    background: #f7f7f7;
    resize: none;
    color: #d6d3d6
}

.stiDesignerTextBoxEnumerator_Mouse {
    border: 1px solid #ababab;
    color: #444;
    background: #fff
}

.stiDesignerTextBoxEnumeratorOver_Mouse {
    border: 1px solid #a3bde3;
    color: #444;
    background: #fff
}

.stiDesignerTextBoxEnumeratorDisabled_Mouse {
    border: 1px solid #e1e1e1;
    color: #d6d3d6;
    background: #fff
}

.stiDesignerTextBoxEnumerator_Touch {
    border: 1px solid #ababab;
    color: #444;
    background: #fff
}

.stiDesignerTextBoxEnumeratorOver_Touch {
    border: 1px solid #a3bde3;
    color: #444;
    background: #fff
}

.stiDesignerTextBoxEnumeratorDisabled_Touch {
    border: 1px solid #e1e1e1;
    color: #d6d3d6;
    background: #fff
}

.stiDesignerTextBoxEnumerator_TextBoxDefault {
    color: #444
}

.stiDesignerTextBoxEnumerator_TextBoxDisabled {
    color: #d6d3d6
}

.stiDesignerTextBoxEnumerator_TextBox_Mouse {
    height: 21px;
    border: 0;
    -webkit-border-radius: 0;
    background: #fff
}

.stiDesignerTextBoxEnumerator_TextBox_Touch,
.stiDesignerTextBox_Mouse {
    padding: 0 0 0 4px;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, 0)));
    background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0))
}

.stiDesignerTextBoxEnumerator_TextBox_Touch {
    font-size: 12px;
    height: 26px;
    border: 0;
    margin: 0;
    font-family: Arial;
    -webkit-border-radius: 0;
    outline: 0;
    background: #fff
}

.stiDesignerTextBoxEnumeratorButton_Mouse {
    height: 10px;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerTextBoxEnumeratorButtonOver_Mouse {
    height: 10px;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    background: #d5e1f2;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerTextBoxEnumeratorButtonSelected_Mouse {
    height: 10px;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    background: #c2d5f2;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerTextBoxEnumeratorButtonDisabled_Mouse {
    height: 10px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerTextBoxEnumeratorButtonOver_Touch,
.stiDesignerTextBoxEnumeratorButtonSelected_Touch,
.stiDesignerTextBoxEnumeratorButton_Touch {
    color: #444;
    height: 12px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerTextBoxEnumeratorButtonOver_Touch {
    background: #d5e1f2
}

.stiDesignerTextBoxEnumeratorButtonSelected_Touch {
    background: #c2d5f2
}

.stiDesignerTextBoxEnumeratorButtonDisabled_Touch {
    height: 12px;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerTextBoxEditButton {
    border: 1px solid #ababab;
    background: #fff;
    border-Left: 0;
    padding: 0;
    margin: 0;
    font-size: 0
}

.stiDesignerTextBoxEditButtonDisabled {
    border: 1px solid #e1e1e1;
    background: #fff;
    border-Left: 0;
    padding: 0;
    margin: 0;
    font-size: 0
}

.stiDesignerMarginsControl,
.stiDesignerTextBox_Mouse {
    font-size: 12px;
    margin: 0;
    font-family: Arial;
    outline: 0;
    color: #444
}

.stiDesignerMarginsControl {
    background: #fff;
    padding: 0;
    -webkit-border-radius: 0;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, 0)));
    background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0))
}

.stiDesignerMarginsControlInnerCell {
    border-right: 1px solid #ababab
}

.stiDesignerMarginsControlInnerCellOver {
    border-right: 1px solid #a3bde3
}

.stiDesignerMarginsControlDefault {
    border: 1px solid #ababab
}

.stiDesignerMarginsControlOver {
    border: 1px solid #a3bde3
}

.stiDesignerMarginsControlDisabled {
    border: 1px solid #e1e1e1
}

.stiDesignerTextBox_Mouse {
    border: 1px solid #ababab;
    -webkit-border-radius: 0;
    background: #fff
}

.stiDesignerTextBoxDisabled_Mouse,
.stiDesignerTextBoxOver_Mouse {
    font-size: 12px;
    margin: 0;
    padding: 0 0 0 4px;
    font-family: Arial;
    outline: 0;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, 0)));
    background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0))
}

.stiDesignerTextBoxOver_Mouse {
    border: 1px solid #a3bde3;
    -webkit-border-radius: 0;
    background: #fff;
    color: #444
}

.stiDesignerTextBoxDisabled_Mouse {
    border: 1px solid #e1e1e1;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -webkit-border-radius: 0;
    background: #f7f7f7;
    color: #d6d3d6
}

.stiDesignerTextBoxOver_Touch,
.stiDesignerTextBox_Touch {
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, 0)));
    background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0));
    font-size: 12px;
    padding: 0 0 0 4px;
    font-family: Arial;
    outline: 0;
    margin: 0
}

.stiDesignerTextBox_Touch {
    border: 1px solid #ababab;
    -webkit-border-radius: 0;
    background: #fff;
    color: #444
}

.stiDesignerTextBoxOver_Touch {
    border: 1px solid #a3bde3;
    -webkit-border-radius: 0;
    background: #fff
}

.stiDesignerTextBoxDisabled_Touch {
    font-size: 12px;
    border: 1px solid #e1e1e1;
    margin: 0;
    padding: 0 0 0 4px;
    font-family: Arial;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -webkit-border-radius: 0;
    outline: 0;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, 0)));
    background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0));
    background: #f7f7f7;
    color: #d6d3d6
}

.stiDesignerToolButtonCell,
.stiDesignerToolButtonSelectedCell {
    border: 0;
    padding: 0;
    margin: 0;
    vertical-align: bottom
}

.stiDesignerToolButtonOver_Mouse,
.stiDesignerToolButton_Mouse {
    color: #fff;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    padding: 0 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    white-space: nowrap;
    height: 24px;
    border: 0
}

.stiDesignerToolButton_Mouse {
    background: #19478a
}

.stiDesignerToolButtonOver_Mouse {
    background: #3b629b
}

.stiDesignerToolButtonSelected_Mouse {
    height: 24px;
    white-space: nowrap;
    background: #fff;
    color: #19478a;
    border: 0;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    padding: 0 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerFileButtonOver_Mouse,
.stiDesignerFileButton_Mouse,
.stiDesignerToolButtonOver_Touch,
.stiDesignerToolButton_Touch {
    color: #fff;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    padding: 0 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    white-space: nowrap
}

.stiDesignerFileButton_Mouse {
    height: 24px;
    background: #19478a
}

.stiDesignerFileButtonOver_Mouse {
    height: 24px;
    background: #3b629b
}

.stiDesignerToolButton_Touch {
    height: 26px;
    background: #19478a;
    border-bottom: 0
}

.stiDesignerToolButtonOver_Touch {
    height: 26px;
    background: #3b629b;
    border-bottom: 0
}

.stiDesignerToolButtonSelected_Touch {
    height: 26px;
    white-space: nowrap;
    background: #fff;
    color: #19478a;
    border-bottom: 0;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    padding: 0 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerFileButtonOver_Touch,
.stiDesignerFileButton_Touch,
.stiDesignerToolButtonDinamic_Mouse,
.stiDesignerToolButtonOverDinamic_Mouse {
    color: #fff;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    padding: 0 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    white-space: nowrap
}

.stiDesignerFileButton_Touch {
    height: 26px;
    background: #19478a
}

.stiDesignerFileButtonOver_Touch {
    height: 26px;
    background: #3b629b
}

.stiDesignerToolButtonDinamic_Mouse {
    height: 24px;
    background: #11315f;
    border: 0
}

.stiDesignerToolButtonOverDinamic_Mouse {
    height: 24px;
    background: #3b629b;
    border: 0
}

.stiDesignerToolButtonSelectedDinamic_Mouse {
    height: 24px;
    white-space: nowrap;
    background: #fff;
    color: #19478a;
    border: 0;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    padding: 0 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerToolButtonDinamic_Touch,
.stiDesignerToolButtonOverDinamic_Touch {
    color: #fff;
    height: 26px;
    font-family: Arial;
    padding: 0 12px;
    cursor: default;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    font-size: 12px;
    border: 0;
    white-space: nowrap
}

.stiDesignerToolButtonDinamic_Touch {
    background: #11315f
}

.stiDesignerToolButtonOverDinamic_Touch {
    background: #3b629b
}

.stiDesignerToolButtonSelectedDinamic_Touch {
    height: 26px;
    white-space: nowrap;
    background: #fff;
    color: #19478a;
    border: 0;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    padding: 0 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerToolTip {
    position: absolute;
    border: 1px solid #c6c6c6;
    padding: 0;
    background: #fff;
    cursor: default;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    z-index: 44;
    max-width: 300px;
    box-shadow: 0 0 7px rgba(0, 0, 0, .3)
}

.stiDesignerToolTipTextCell {
    font-size: 12px;
    font-family: Arial;
    color: #444;
    padding: 10px;
    border: 0;
    border-bottom: 1px solid #c6c6c6;
    white-space: normal
}

.stiDesignerTreeItemButtonSelected_Mouse,
.stiDesignerTreeItemButtonSelected_Touch,
.stiDesignerTreeItemButton_Mouse,
.stiDesignerTreeItemButton_Touch {
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 11px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerConnectionsTree {
    width: 400px;
    height: 400px;
    overflow: auto
}

.stiDesignerTreeItemIconOpening_Mouse {
    margin: 0 3px 0 0
}

.stiDesignerTreeItemButton_Mouse {
    border: 1px solid transparent;
    padding: 0
}

.stiDesignerTreeItemButtonSelected_Mouse {
    border: 1px solid #97b2db;
    padding: 0;
    background: #d5e1f2
}

.stiDesignerTreeItemButtonImage_Mouse {
    border: 0;
    padding: 0;
    margin: 1px 2px
}

.stiDesignerTreeItemButtonCaption_Mouse {
    margin: 0;
    border: 0;
    padding: 0 2px 0 0
}

.stiDesignerTreeItemIconOpening_Touch {
    margin: 3px
}

.stiDesignerTreeItemButton_Touch {
    border: 1px solid #fff;
    padding: 0
}

.stiDesignerTreeItemButtonSelected_Touch {
    border: 1px solid #97b2db;
    padding: 0;
    background: #d5e1f2
}

.stiDesignerTreeItemButtonSelectedNotActive {
    border: 1px solid #f0f0f0;
    background: #f0f0f0
}

.stiDesignerTreeItemButtonImage_Touch {
    border: 0;
    padding: 0;
    margin: 4px 2px 4px 4px
}

.stiDesignerTreeItemButtonCaption_Touch {
    margin: 0;
    border: 0;
    padding: 3px 5px 3px 2px
}

.stiDesignerTreeItemForDragDrop_Mouse,
.stiDesignerTreeItemForDragDrop_Touch {
    padding: 0;
    background: #d5e1f2;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    opacity: .6
}

.stiDesignerTreeItemForDragDrop_Mouse {
    border: 1px solid #97b2db;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    box-shadow: 0 0 7px rgba(0, 0, 0, .3)
}

.stiDesignerTreeItemForDragDrop_Touch {
    border: 1px solid #97b2db;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0 0 7px rgba(0, 0, 0, .6);
    -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, .6);
    box-shadow: 0 0 7px rgba(0, 0, 0, .3)
}

.stiDesignerVerticalButtonDisabled_Mouse,
.stiDesignerVerticalButtonOver_Mouse,
.stiDesignerVerticalButtonSelected_Mouse,
.stiDesignerVerticalButton_Mouse {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    width: 80px;
    text-overflow: ellipsis;
    text-align: center;
    margin: 35px 0 0 -30px;
    padding: 1px 2px 2px
}

.stiDesignerVerticalButton_Mouse {
    border-bottom: 7px solid #19478a;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerVerticalButtonOver_Mouse {
    border-bottom: 7px solid #3b629b;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerVerticalButtonSelected_Mouse {
    border-bottom: 7px solid #11315f;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerVerticalButtonDisabled_Mouse {
    border-bottom: 7px solid #6b8ab8;
    color: #d6d3d6;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.stiDesignerVerticalButtonOver_Touch,
.stiDesignerVerticalButtonSelected_Touch,
.stiDesignerVerticalButton_Touch {
    color: #444;
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    width: 80px;
    text-overflow: ellipsis;
    margin: 35px 0 0 -30px;
    padding: 1px 2px 2px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    text-align: center
}

.stiDesignerVerticalButton_Touch {
    border-bottom: 7px solid #19478a
}

.stiDesignerVerticalButtonOver_Touch {
    border-bottom: 7px solid #3b629b
}

.stiDesignerVerticalButtonSelected_Touch {
    border-bottom: 7px solid #11315f
}

.stiDesignerVerticalButtonDisabled_Touch {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    width: 80px;
    text-overflow: ellipsis;
    text-align: center;
    margin: 35px 0 0 -30px;
    padding: 1px 2px 2px;
    border-bottom: 7px solid #6b8ab8;
    color: #d6d3d6;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.wizardFormMainTable {
    border: 0;
    padding: 0
}

.wizardFormInfoCell {
    background: #fff;
    border: 0;
    border-bottom: 1px dotted #c6c6c6;
    padding: 0 0 0 20px;
    height: 55px;
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    line-height: 1.7
}

.wizardFormStepsPanel {
    background: #fff;
    border: 0;
    border-right: 1px dotted #c6c6c6;
    padding: 0;
    vertical-align: top
}

.wizardFormWorkPanel {
    overflow-y: auto;
    height: 380px;
    width: 550px
}

.wizardFormColumnsOrderItemsContent {
    overflow: auto;
    height: 380px;
    width: 520px;
    border-right: 1px dotted #c6c6c6
}

.wizardFormColumnsOrderButtonsContent {
    height: 380px;
    width: 29px;
    text-align: center
}

.wizardFormColumnsItemsContent {
    overflow: auto;
    height: 340px;
    border-bottom: 1px dotted #c6c6c6
}

.wizardFormColumnsItemsContentWithoutButtons {
    overflow: auto;
    height: 380px
}

.wizardFormDataSourcesContent {
    border-top: 1px dotted #c6c6c6;
    overflow: auto;
    height: 303px;
    border-bottom: 1px dotted #c6c6c6;
    background: #f9f9f9
}

.wizardFormDataSourcesContentWithoutButtons {
    border-top: 1px dotted #c6c6c6;
    overflow: auto;
    height: 343px;
    background: #f9f9f9
}

.wizardFormColumnsButtonsContent {
    height: 39px
}

.wizardFormColumnsButtonsTable {
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%
}

.wizardFormTotalsHeaderColumn,
.wizardFormTotalsHeaderFunction {
    color: #949494;
    padding: 3px 0;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    text-align: center
}

.wizardFormTotalsTable {
    border: 1px solid #c6c6c6;
    border-collapse: collapse;
    margin: 5px
}

.wizardRelationsFormItemsContainer {
    overflow: auto;
    width: 500px;
    height: 350px;
    border: 1px dotted #c6c6c6
}

.wizardFormTotalsHeaderColumn {
    background: #eee;
    width: 380px;
    border: 0;
    border-right: 1px dotted #c6c6c6;
    white-space: nowrap
}

.wizardFormTotalsHeaderFunction {
    background: #eee;
    border: 0;
    white-space: nowrap
}

.wizardFormTotalsCellColumn {
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    border: 0;
    margin: 0;
    border-bottom: 1px solid #c6c6c6;
    padding: 0;
    white-space: nowrap
}

.wizardFormTotalsDivColumn {
    width: 380px;
    overflow: hidden;
    padding: 6px;
    white-space: nowrap
}

.wizardFormTotalsCellFunction {
    color: #444;
    cursor: default;
    font-family: Arial;
    font-size: 12px;
    border: 0;
    margin: 0;
    border-left: 1px solid #c6c6c6;
    padding: 0 6px;
    border-bottom: 1px solid #c6c6c6
}

.wizardFormStepItem,
.wizardFormStepItemSelected {
    font-size: 12px;
    padding: 7px 40px 7px 10px;
    text-align: left;
    cursor: default;
    font-family: Arial;
    white-space: nowrap
}

.wizardFormThemesTableSeparator {
    border-bottom: 1px dotted #c6c6c6;
    height: 1px
}

.wizardFormStepItem {
    color: #444
}

.wizardFormStepItemSelected {
    background: #19478a;
    color: #fff
}

.wizardFormSeparator {
    border-bottom: 1px dotted #c6c6c6;
    margin: 2px 3px;
    padding: 6px;
    color: #19478a;
    cursor: default;
    font-family: Arial;
    font-size: 13px;
    white-space: nowrap
}

.wizardFormHintText {
    white-space: normal;
    line-height: 1.5;
    border: 0;
    font-family: Arial;
    font-size: 12px;
    color: #696969;
    opacity: .6;
    position: absolute
}

.wizardStepPanel {
    width: 100%;
    height: 410px;
    overflow-y: auto
}

.wizardStepCaption {
    background: #3b629b;
    font: 700 12px Arial;
    color: #fff;
    padding-left: 30px;
    height: 2.5em;
    line-height: 2.5em
}

.wizardMappingText {
    font: 700 12px Arial;
    text-align: right;
    padding: 8px
}

.wizardLablesText {
    font: 12px Arial;
    text-align: left;
    padding: 8px
}

.wizardStepEllipse {
    fill: #3b629b
}

.wizardStepEllipseChecked {
    fill: #11315f
}

.wizardStepEllipseInactive {
    fill: #ebebeb
}

.wizardStepNumber {
    text-anchor: middle;
    font: 700 14px Arial
}

.wizardStepText,
.wizardStepTextChecked,
.wizardStepTextInactive {
    text-anchor: middle;
    font: 700 12px Arial
}

.wizardStepText {
    fill: #3b629b
}

.wizardStepTextChecked {
    fill: #11315f
}

.wizardStepTextInactive {
    fill: #898989
}

.wizardStepLine {
    stroke: #3b629b
}

.wizardStepLineCheked {
    stroke: #11315f
}

.wizardStepLineInactive {
    stroke: #ebebeb
}

.stiDesignerZoomScale_Mouse {
    position: relative;
    width: 100px;
    cursor: default;
    height: 20px
}

.stiDesignerZoomScaleLine_Mouse {
    position: absolute;
    top: 10px;
    cursor: default;
    height: 1px;
    width: 100%;
    background: #fff;
    z-index: 1
}

.stiDesignerZoomScaleButtonDisabled_Mouse,
.stiDesignerZoomScaleButtonOver_Mouse,
.stiDesignerZoomScaleButton_Mouse {
    top: 3px;
    height: 15px;
    width: 5px;
    cursor: default;
    position: absolute
}

.stiDesignerZoomScaleButton_Mouse {
    background: #fff;
    z-index: 100
}

.stiDesignerZoomScaleButtonOver_Mouse {
    background: #c2d5f2;
    z-index: 100
}

.stiDesignerZoomScaleButtonDisabled_Mouse {
    background: #6b8ab8;
    z-index: 1
}

.stiDesignerZoomScale_Touch {
    position: relative;
    width: 150px;
    cursor: default;
    height: 30px
}

.stiDesignerZoomScaleLine_Touch {
    position: absolute;
    top: 15px;
    cursor: default;
    height: 1px;
    width: 100%;
    background: #fff;
    z-index: 1
}

.stiDesignerZoomScaleButtonOver_Touch,
.stiDesignerZoomScaleButton_Touch {
    position: absolute;
    top: 0;
    cursor: default;
    height: 30px;
    width: 30px;
    z-index: 100
}

.stiDesignerZoomScaleButtonDisabled_Touch {
    position: absolute;
    top: 0;
    cursor: default;
    height: 30px;
    width: 30px;
    z-index: 1
}

.stiDesignerZoomScaleMiddle {
    position: absolute;
    height: 7px;
    background: #fff;
    width: 1px;
    z-index: 1
}

.stiDataColumnContainer {
    font-family: Arial;
    font-size: 12px;
    color: #d6d3d6;
    border: 1px dashed #c6c6c6;
    text-align: center;
    white-space: nowrap;
    padding: 1px;
    position: relative
}

.stiDataColumnContainerOver {
    border: 1px dashed #a3bde3
}

.stiMapManualContainer {
    border: 1px dashed #ababab
}

.stiMapScrollContainer {
    overflow: auto
}

.stiMapDataGridHeader td {
    background: #eee;
    color: #444;
    font-family: Arial;
    font-size: 12px;
    white-space: nowrap;
    padding: 4px 0;
    text-align: center;
    border-bottom: 1px solid #c6c6c6;
    border-right: 1px solid #c6c6c6
}

.stiDesignerWizardButtonDisabled_Mouse,
.stiDesignerWizardButtonOver_Mouse,
.stiDesignerWizardButtonOver_Touch,
.stiDesignerWizardButtonSelected_Mouse,
.stiDesignerWizardButtonSelected_Touch,
.stiDesignerWizardButton_Mouse,
.stiDesignerWizardButton_Touch {
    color: #fff;
    cursor: pointer;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    margin: 3px;
    height: 30px
}

.stiDesignerWizardButton_Mouse {
    background: #a0a0a0
}

.stiDesignerWizardButtonOver_Mouse,
.stiDesignerWizardButtonSelected_Mouse {
    background: #3b629b
}

.stiDesignerWizardButtonDisabled_Mouse {
    background: #fff
}

.stiDesignerWizardButton_Touch {
    background: #a0a0a0
}

.stiDesignerWizardButtonOver_Touch,
.stiDesignerWizardButtonSelected_Touch {
    background: #3b629b
}

.stiDesignerWizardButtonDisabled_Touch {
    background: #fff;
    height: 30px;
    color: #fff;
    cursor: pointer;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    margin: 3px
}

.stiDesignerWizardThemeButtonDisabled_Mouse,
.stiDesignerWizardThemeButtonDisabled_Touch,
.stiDesignerWizardThemeButtonOver_Mouse,
.stiDesignerWizardThemeButtonOver_Touch,
.stiDesignerWizardThemeButtonSelected_Mouse,
.stiDesignerWizardThemeButtonSelected_Touch,
.stiDesignerWizardThemeButton_Mouse,
.stiDesignerWizardThemeButton_Touch {
    height: 210px;
    color: #fff;
    cursor: pointer;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    margin: 3px
}

.stiDesignerWizardThemeButton_Mouse {
    border: 1px solid #fff
}

.stiDesignerWizardThemeButtonOver_Mouse,
.stiDesignerWizardThemeButtonSelected_Mouse {
    border: 1px solid #ef9341
}

.stiDesignerWizardThemeButtonDisabled_Mouse,
.stiDesignerWizardThemeButton_Touch {
    border: 1px solid #fff
}

.stiDesignerWizardThemeButtonOver_Touch,
.stiDesignerWizardThemeButtonSelected_Touch {
    border: 1px solid #ef9341
}

.stiDesignerWizardThemeButtonDisabled_Touch {
    border: 1px solid #fff
}

.stiDesignerSvgButton {
    color: #fff;
    fill: #19478a
}

.stiDesignerSvgButtonOver {
    color: #fff;
    fill: #3b629b
}

.stiDesignerSvgButtonDisabled {
    color: #6b8ab8;
    fill: #eee
}

.stiDesignerSvgButtonSeparator {
    stroke: #3b629b
}

.stiDesignerSvgButtonArrow {
    fill: #19478a;
    stroke: #19478a;
    stroke-width: 1
}

.stiDesignerSliderControl {
    height: 25px;
    background: #fff;
    position: relative
}

.stiDesignerSliderControlLine {
    position: absolute;
    background: #ababab;
    height: 1px;
    width: 100%;
    top: 12px
}

.stiDesignerSliderControlButton {
    position: absolute;
    top: 2px;
    height: 21px
}

.stiDesignerSliderControlButtonDefault {
    background: #ababab
}

.stiDesignerSliderControlButtonOver {
    background: #a3bde3
}

.stiDesignerCloudWizardButton_Mouse {
    background: #19478a;
    height: 25px;
    color: #fff;
    cursor: pointer;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    margin: 3px
}

.stiDesignerCloudWizardButtonOver_Mouse {
    background: #3b629b;
    height: 25px;
    color: #fff;
    cursor: pointer;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    margin: 3px
}

.stiDesignerCloudWizardButtonSelected_Mouse {
    background: #11315f;
    height: 25px;
    color: #fff;
    cursor: pointer;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    margin: 3px
}

.stiDesignerCloudWizardButtonDisabled_Mouse {
    background: #fff;
    height: 25px;
    color: #fff;
    cursor: pointer;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    margin: 3px
}

.stiDesignerCloudWizardButtonDisabled_Touch,
.stiDesignerCloudWizardButtonOver_Touch,
.stiDesignerCloudWizardButtonSelected_Touch,
.stiDesignerCloudWizardButton_Touch {
    height: 30px;
    color: #fff;
    cursor: pointer;
    font-family: Arial;
    font-size: 12px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    margin: 3px
}

.stiDesignerCloudWizardButton_Touch {
    background: #19478a
}

.stiDesignerCloudWizardButtonOver_Touch,
.stiDesignerCloudWizardButtonSelected_Touch {
    background: #3b629b
}

.stiDesignerCloudWizardButtonDisabled_Touch {
    background: #fff
}