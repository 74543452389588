
  .attachment-adjust{
      padding: 0px 50px 0px 50px;
      color: #7B7B7B;
      font-size: 20px;
  }
  .attachment-adjust li{
      word-break: break-all;
  }
  .attachment-adjust li img{
      cursor: pointer;
  }
  .attachment-heading{
      background-color: #EEE;
      font-family: 'SegoeBold';
      
  }
.drag-file-img{
    text-align: center;
    border: 1px solid #E9E9E9;
    margin: 32px 0px 23px 0px;
}


.chk-appr-input  {
    box-sizing: border-box;
    padding: 0px;
    transform: scale(1.8);
    margin-left: 11px;
}
