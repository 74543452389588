.mm_report_new_modal .modal-dialog.modal-xl {
    max-width: 1276px !important;
}
.mm-searchbar_new .search_input {
    background-color: #fff;
    border: 1px solid #e7e7e7;
}
.mm-searchbar_new .search_icon {
    color: #e7e7e7;
}
.mm-chart2  thead th, .mm-chart2  tbody td {
    padding: 10px 18px;
}
.mm-chart2 {
    margin-bottom: 70px;
}
.mm-blok21{
    right: 20px;
    margin-top: 61px;
}
@media (max-width:767px){
    .mob-order1 {
        order: 1;
    }
    .mob-order2 {
        order: 2;
    }
}