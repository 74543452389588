.activity_item_main {
  border-bottom: 1px solid #e3e3e3;
  padding: 30px 0px 35px 0px;
}
.activity_9 p {
  color: #7b7b7b;
  font-size: 14px !important;
  width: 396px !important;
}
.activity_3 p {
  color: #7b7b7b;
  font-size: 10px !important;
  width: 115px !important;
}
.forgot_body .col-md-12:last-child .activity_item_main {
  border-bottom: none !important;
}
