.file-upload-status-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 500px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #eee;
  padding: 15px;
  z-index: 99;
}

.file-upload-status-wrapper .file-upload {
  margin-bottom: 10px;
}

.file-upload-status-wrapper .minimize-btn {
  border: 1px solid #ddd;
  font-size: 11px;
  border-radius: 3px;
  outline: none;
}

.file-upload-status-wrapper h5 {
  margin-bottom: 0px;
}

.file-upload-status-wrapper.minimized {
  width: 250px !important;
}
