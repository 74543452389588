/* .exp_upload_files1 ul li {
    width: 145%;
    display: inline-block;
    text-align: left;
    padding: 4px 5px;
    margin: 5px;
    font-size: 12px;
    border-radius: 5px;
    position: relative;
} */

.exp_upload_files1 ul li {
    width: 145%;
    display: inline-block;
    text-align: left;
    padding: 4px 5px;
    margin: 5px;
    font-size: 12px;
    border-radius: 5px;
    position: relative;
}

.exp_upload_files1 ul .green_li span {
    color: #32cd32;
}

.exp_upload_files1 ul .green_li span.fa-times {
    float: right;
    position: absolute;
    top: 8px;
    right: 5px;
}

.exp_upload_files1 ul .green_li {
    color: #32cd32;
    border: 1px solid #32cd32;
    background-color: rgb(218, 231, 218);
}

.exp_upload_files1 ul .blue_li {
    color: #30679a;
    border: 1px solid #30679a;
    background-color: rgb(204, 220, 233);
}

.exp_upload_files1 ul .blue_li span {
    color: #30679a;
}

.exp_upload_files1 ul .blue_li span.fa-times {
    float: right;
    position: absolute;
    top: 8px;
    right: 5px;
}

.exp_upload_files1 p {
    display: inline-block;
    margin: 0;
    padding-left: 5px;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1;
}


