body,
.modal-content {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    text-shadow: none;
}

.invoice-ocr_popup__table tbody tr {
    box-shadow: 0px 1px 2px rgba(197, 197, 197, 0.85) !important;
    -webkit-box-shadow: 0px 1px 5px rgba(197, 197, 197, 0.85) !important;
}

.invoice-ocr_popup__table table {
    box-shadow: 0px 5px 4px rgba(0,0,0,0.1);
}

.modal__InvoiceOcr .modal-lg {
    width: 486px !important;
}

.modal__InvoiceOcr .modal-body {
    padding: 0;
}

.invoice-ocr-poup_body {
    padding: 0px 20px 20px;
}

.invoice-ocr-poup_body label {
    margin-bottom: 0;
    font-size: 13px;
    position: relative;
}
.invoice-ocr_popup__table label {
    top: 0;
    margin-bottom: 12px;
}

.invoice-ocr-poup_body input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #e9e9f0;
    font-size: 12px;
    color: #000000;
}
.invoice-ocr_popup__table thead th,
.invoice-ocr_popup__table tbody td {
    width: 122px;
}

.invoice-ocr_popup__table label {
    top: 0;
    margin-bottom: 12px;
}

.invoice-ocr_popup_select .custon_select-selector-inner__control {
    border-bottom: 1px solid #e9e9f0 !important;
    min-height: 25px;
}

.invoice-ocr_popup_select .custon_select-selector-inner__control.css-e56m7-control:after {
    right: 0px;
    color: #858585 !important;
    font-size: 14px !important;
    top: 41%;
}

.invoice-ocr_popup__table thead th {
    color: #fff;
    font-size: 13px;
}

.invoice-ocr_popup__table tbody td {
    font-size: 13px;
    color: #616060;
}

.invoice-ocr_popup_select .css-1wa3eu0-placeholder {
    font-size: 12px !important;
    color: #000000;
}


.invoice-ocr_popup__table tbody {
    border: 1px solid rgba(197, 197, 197, 0.85)
}
.reports_icons_ul {
    line-height: 10px;
}

.invoice-ocr-poup_body input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #e9e9f0;
    font-size: 12px;
    color: #000000;
}

.invoice-ocr_popup_select .custon_select-selector-inner__control {
    border-bottom: 1px solid #e9e9f0 !important;
    min-height: 25px;
}

.invoice-ocr_popup_select .custon_select-selector-inner__control.css-e56m7-control:after {
    right: 0px;
    color: #858585 !important;
    font-size: 14px !important;
    top: 41%;
}

.invoice-ocr_popup__table thead th {
    color: #fff;
    font-size: 13px;
}

.invoice-ocr_popup__table tbody td {
    font-size: 13px;
    color: #616060;
}

.invoice-ocr_popup_select .css-1wa3eu0-placeholder {
    font-size: 12px !important;
    color: #000000;
}


.invoice-ocr_popup__table tbody {
    border: 1px solid rgba(197, 197, 197, 0.85)
}
.invoice-ocr_popup__table h2 {
    font-weight: bold;
    font-size: 18.66px;
    color: #4c4c4c;
    padding-bottom: 22px;
    margin-bottom: 0;
    padding-top: 10px;
    display: inline-block;
}

.invoice-ocr_popup__table thead th {
    color: #fff;
    font-size: 13px;
    text-transform: capitalize;
}

.invoice-ocr_popup__table tbody td {
    font-size: 13px;
    color: #616060;
}

.invoice-ocr_popup_select .css-1wa3eu0-placeholder {
    font-size: 12px !important;
    color: #000000;
}

.invoice-ocr_popup__table thead th:first-child,
.invoice-ocr_popup__table tbody td:first-child {
    width: auto;
}
.invoiceoccr_search textarea{
    height: 76px;
    border: 1px solid rgba(197, 197, 197, 0.85);
    border-radius: 3px;
    width: 100%;
    margin-top: 12px;
}
.invoiceoccr_search textarea:focus{
    box-shadow: 0px 0px 3px 3px #a6c8ff;
    outline: none;
}
@media (min-width:1920px) and (max-width:4000px) {

    .invoice-ocr_popup__table thead th,
    .invoice-ocr_popup__table tbody td {
        width: 122px;
    }

}
@media (min-width:2560px) and (max-width:4000px) {

    .invoice-ocr_popup__table thead th,
    .invoice-ocr_popup__table tbody td {
        width: 122px;
    }

}

@media (min-width:4000px) and (max-width:8000px) {

    .invoice-ocr_popup__table thead th,
    .invoice-ocr_popup__table tbody td {
        width: 120px;
    }
}