.new_advance_table {
  border-radius: 20px;
  padding: 0px 42px;
}
.new_advance_table .thead_bg {
  background-color: rgba(46, 115, 174) !important;
}

 .new_advance_table1 {
   border-radius: 20px;
   padding: -1px 42px;
 }

 .new_advance_table1 .thead_bg {
   background-color: rgba(46, 115, 174) !important;
 }

 .new_advance_table1 th {
   color: #FFFFFF;
 }

 .new_advance_table1 td {
   color: #5a5a5a;
 }
.run_bg {
  background-color: rgba(46, 115, 174);
  color: white;
  padding: 3px 8px;
}
.w_50{
  width: 55%;
}
