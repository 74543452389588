.mm_toggle_new_main {
  padding: 0px;
}
.mm_toggle_new_main .fa-angle-up {
  margin-top: 6px;
}
.mm_toggle_new_main ul li {
  text-transform: uppercase;
  font-size: 14px;
  display: block;
  color: #43425d;
}
.mm_toggle_new_main ul {
  margin-bottom: 0;
  background-color: #fbfbfb;
  padding: 10px 40px;
}
.mm-table-outer2 {
  width: 100%;
  display: inline-block;
  height: 100%;
  text-align: right;
  padding-right: 20px;
  position: relative;
  top: -38px;
}
.mm-table-outer2 .dropdown-menu-right {
  height: calc(100vh - 100px);
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  width: 337px;
  padding: 0;
  background-color: #fbfbfb;
  overflow: auto;
}
.mm-table-outer2 .dropdown-menu-right .dropdown-item,
.mm_toggle_new_main {
  font-weight: bold;
  background-color: #fff;
  box-shadow: 0px 3px 6px #0000000d;
  -webkit-box-shadow: 0px 3px 6px #0000000d;
  margin: 10px 0;
  font-size: 18px;
  color: #7b7b7b;
  cursor: pointer;
  padding: 10px;
}
.mm-table-outer2 .dropdown-menu-right .dropdown-item:first-child,
.mm_toggle_new_main {
  margin-top: 0;
}
.mm-table-outer2 .mm_toggle_new_main .attach-text {
  padding-left: 16px;
}
.mm_name_attached2 {
  font-size: 18px;
  color: #7b7b7b;
  cursor: pointer;
  font-weight: bold;
  background-color: #fff;
}
.mm_toggle_new_main .fa-angle-up {
  font-size: 18px;
  font-weight: bold;
}
/* .sideBarAccord1 {
  color: #7b7b7b;
  font-weight: bold;
} */
.mm-genrel_lager {
  overflow: hidden;
}
.ladger-table-new thead th:nth-child(2) {
  padding-left: 18px;
}
.ladger-table-new.dataTable tbody td:first-child {
  padding-left:5px;
}
.ladger-table-new.dataTable tbody td {
  padding-left: 17px;
}
.ladger-table-new.dataTable tbody td:nth-child(3), .ladger-table-new.dataTable tbody td:nth-child(4),
.ladger-table-new.dataTable tbody td:nth-child(5),
.ladger-table-new.dataTable tbody td:nth-child(6) {
  padding-left:13px;
}
.ladger-table-new.dataTable tbody td:nth-child(2) {
  padding-left: 10px;
}
.login_form .ladger-table-new input {
  padding-left: 0;
}
/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.mm-newswitch input.default:checked + .slider {
  background-color: #2f73ad;
}

.mm-newswitch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.mm-newswitch {
  display: inline-block;
  margin-right: 20px;
    background: transparent;
    border: 0;
    float: right;
}

/* Rounded sliders */
.mm-newswitch .slider.round {
  border-radius: 34px;
    width: 60px;
    height: 33px;
}

.mm-newswitch .slider.round:before {
  border-radius: 50%;
}