.financial_table thead {
  background-color: #2f73ad;
}
.financial_table thead th {
  color: #fff;
}
.financial_table tbody td,
.financial_table thead th {
  padding: 10px 18px;
  font-size: 13px;
  text-transform: capitalize;
  white-space: nowrap;
}
.financial_table tbody td {
  color: #595959;
}
.financial_table tbody .active-row td {
  color: #2f73ad;
}
.financial_table tbody .hiddenRow {
  border-bottom: 0;
}
.financial-pagination {
  width: 100%;
  text-align: center;
  margin-top: 40px;
  padding: 0px 10px;
}
.financial-pagination .pagination {
  margin: 0 166px;
  justify-content: center;
}
.financial-pagination .page-item .page-link {
  border-radius: 50%;
  margin: 0px 6px;
  color: #666;
  padding: 3px 8px;
}
.financial-pagination .page-item.active .page-link {
  background-color: #2f73ad;
  color: #fff;
}
.financial-pagination .page-item:first-child .page-link,
.financial-pagination .page-item:last-child .page-link {
  border-radius: 0;
  border: 0;
}
.show-entries {
  float: left;
}
.mm-top-new {
  margin-top: 61px;
  right: 20px;
}
.placeHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  padding: 60px 30px;
}
.placeHolder h5 {
  color: #30679a;
  font-weight: bold;
  margin-top: 85px;
}
@media (max-width: 767px) {
  .mob-order1 {
    order: 1;
  }
  .mob-order2 {
    order: 2;
  }
}
