.company-detail_check .custom-radio {
    float: left;
    padding-right: 30px;

}
.company-detail_check .click_checkmark {
    top: -16px;
    left: 10px;
}
.modal__company_detail {
    width: 588px !important;

}
.modal__company_detail .modal-body {
    padding: 0;
}
.modal__company_detail h2 {
    font-weight: bold;
    font-size: 18.66px;
    color: #4c4c4c;
    padding-bottom: 22px;
    margin-bottom: 0;
    padding-top: 10px;
}
.modal__company_popup__table thead th, .modal__company_popup__table tbody td {
    width: 135px;
}
.modal__company_poup_body label {
    margin-bottom: 0;
    font-size: 13px;
    position: relative;
    color: #616060;
}
.modal__company_poup_body input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #e9e9f0;
    font-size: 12px;
    color: #000000;
}
.modal__company_popup__table thead th {
    color: #fff;
    font-size: 13px;
}
.modal__company_popup__table tbody tr {
    box-shadow: 0px 1px 2px rgba(197, 197, 197, 0.85) !important;
    -webkit-box-shadow: 0px 1px 5px rgba(197, 197, 197, 0.85) !important;
}
.modal__company_popup__table tbody td {
    font-size: 13px;
    color: #616060;
}
.modal__company_popup__table tbody {
    border: 1px solid rgba(197, 197, 197, 0.85);
}
.modal__company_poup_body {
    padding: 28px 20px 20px;
}
.modal__company_popup__table label {
    top: 0;
    margin-bottom: 13px;
}