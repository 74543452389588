

.table_white_box #example2_filter::before {
    content: " ";
    position: absolute;
    right: 30px;
    top: -5px;
    height: 36px;
    border-left: 1px solid;
  }
  
  .table_white_box div.dataTables_wrapper div.dataTables_filter label {
    position: relative;
    text-indent: -25%;
    width: 100%;
  }
  
  .table_white_box .dataTables_wrapper div.dataTables_filter input {
    margin-left: 0px !important;
  }
  
  .table_white_box #object1 {
    width: 23px;
    height: 27px;
    background: #ff000000;
    position: absolute;
    right: 7px;
    top: 2px;
    z-index: 9;
    cursor: pointer;
  }
  
  .table_white_box #example2_wrapper.dataTables_wrapper {
    position: static;
  }
#filter_dropdpwn1 {
    width: 356px;
    border-radius: 20px;
    border: solid 1px #d0d0d0;
    min-height: 250px;
    box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
    transition: box-shadow 0.2s ease-in-out;
    background-color: #fff;
    /* display: none; */
    position: absolute;
    z-index: 999;
    padding: 13px 0px;
    top: 60px;
    left: 10px;
    display: none;
}
.filter_dropdpwn1_toparea {
    padding: 10px;
}
.filter_dropdpwn1_toparea h2 {
    font-size: 14px;
    color: #383838;
    font-weight: bold;
    margin: 0px;
    margin-top: 5px;
    padding: 0px;
}
.pop-cros-1 {
    color: #c1c1c1;
    cursor: pointer;
}
.filter_table_1 {
    font-size: 14px;
    color: #616060;
}
.active_filters h2 {
    border-bottom: solid 1px #d0d0d0;
    font-size: 14px;
    color: #383838;
    font-weight: bold;
    margin: 0px;
    margin-top: 5px;
    padding: 0px;
    padding-bottom: 8px;
    padding-left: 10px;
}
.save-filter {
    position: absolute;
    right: 0;
    font-size: 12px;
    margin-top: -26px;
    margin-right: 10px;
    text-decoration: none;
    font-weight: bold;
    font-family: 'OpenSans-Regular';
}
.save-filter a {
    text-decoration: none !important;
    color: #2f73ad;
    font-size: 12px;
    font-weight: bold;
}
.active_filters_table2 {
    padding: 10px;
    font-size: 14px;
    color: #616060;
    line-height: 30px;
}
.clear-filter {
    padding: 2px 10px;
    color: #2f73ad;
    background-color: #fff;
    border: none;
    font-weight: bold;
    cursor: pointer;
    font-size: 12px;
}
.plus_icon-filter_bottom {
    margin-right: 10px;
    cursor: pointer;
}
.nofilter {
    font-size: 14px;
    color: #616060;
}
.active_filter_list {
    padding: 22px 13px;
    margin: 0;
    list-style: none;
    padding-top: 0;
}
.active_filter_list li {
    margin: 10px 0px;
    font-size: 14px;
    color: #616060;
}
.active_filter_list li span {
    padding-right: 14px;
}
.active_filter_list li span img {
    width: 10px;
    vertical-align: top;
    padding-top: 5px;
}


/* filter2 right */
#filter_dropdpwn2 {
    width: 367px;
    border-radius: 20px;
    border: solid 1px #d0d0d0;
    min-height: 427px;
    box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
    transition: box-shadow 0.2s ease-in-out;
    background-color: #fff;
    display: block;
    position: absolute;
    z-index: 999;
    top: 27%;
    left: 380px;
}
.filter_dropdpwn2_toparea h2 {
    font-size: 14px;
    color: #383838;
    font-weight: bold;
    margin: 0px;
    margin-top: 5px;
    padding: 0px;
    display: inline-block;
}
.pb-30 {
    padding-bottom: 30px;
}
.sec-pop ul {
    padding-left: 10px;
    padding-right: 10px;
}
.sec-pop ul li {
    list-style: none;
    font-size: 13px;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;
}
.sec-pop1 ul li {
    list-style: none;
    font-size: 13px;
    margin-bottom: 20px;
}
input.cus-in {
    border: 0;
}
.can-sav-btn {
    float: right;
    padding-right: 20px;
    padding-top: 15px;
}
.can-sav-btn .can-btn1 {
    padding: 0;
    margin: 0;
    background-color: transparent;
    color: #2f73ad;
    font-size: 16px;
}
.can-sav-btn .can-btn1 img {
    padding-right: 11px;
}

@media (min-width:4000px) and (max-width:8000px){
    .user_setup_header_rightbox {
        width: calc(100% - 16%);
    }
}
@media (min-width:1901px) and (max-width:4000px){
    .user_setup_header_rightbox {
        width: calc(100% - 377px);
    }
}
@media (min-width:991px) and (max-width:1199px){
    .user_setup_play_div {
        width: 14%;
    }
}
@media screen and (max-width:991px){
    .user_setup_play_div {
        width: 19%;
    }
    .user_setup_header_rightbox {
        padding-top: 3px;
    }
}
@media screen and (max-width:767px){
    .user_setup_play_div {
        width: 24%;
    }
    .user_setup_header_rightbox {
        width: calc(100% - 207px);
        padding-top: 3px;
    }
}
@media screen and (max-width:575px){
    .user_setup_play_div {
        width: 30%;
    }
    .user_setup_header_rightbox {
        width: calc(100% - 185px);
        float: right;
        font-size: 14px;
        color: #333333;
        padding-top: 0px;
    }
}