body,
.modal-content {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  text-shadow: none;
}

.bulk-change-modal-body {
  padding: 20px;
  font-size: 17px;
}
.bulk-change-modal .modules-table thead th {
  color: #ffffff;
  font-weight: bold;
}
.bulk-change-modal .modules-table tbody {
  font-weight: bold;
}
.bulk-change-modal .modules-table .selected-col {
  width: 25%;
  text-align: center;
}
.bulk-change-modal .modules-table .tallies-col {
  width: 25%;
  text-align: center;
}

/*LOADING CONTAINERS*/
.bulk-change-modal .empty-loading-container {
  padding: 10px;
  min-height: 400px;
}

.bulk-change-modal .loading-spinner {
  padding: 20px;
  background: #ffffff;
  border:1px solid #cccccc;
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  z-index: 10000;
}
