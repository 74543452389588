
  .attachment-adjust{
      padding: 0px 50px 0px 50px;
      color: #7B7B7B;
      font-size: 20px;
  }
  .attachment-adjust li{
      word-break: break-all;
  }
  .attachment-adjust li img{
      cursor: pointer;
  }
  .attachment-heading{
      background-color: #EEE;
      font-family: 'SegoeBold';
  }
.drag-file-img{
    text-align: center;
    border: 1px solid #E9E9E9;
    margin: 32px 0px 23px 0px;
}
  .truncate {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  .w-150{
      width: 350px;
  }
  .w-500{
      width: 500px;
  }
.iicon{
    position: absolute;
    top: 7px;
    right: 0px;
}

.attachment-td{
    color: #7B7B7B;
    font-size: 20px;
}
.vertical-mid{
    vertical-align: middle !important;
}