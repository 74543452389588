.comments_main {
  border-bottom: 1px solid #e3e3e3 !important ;
  padding: 14px 0px 10px 0px;
}
.activity_9 p {
  color: #7b7b7b;
  font-size: 20px;
}
.activity_3 p {
  color: #7b7b7b;
  font-size: 16px;
}
.activity_9_h5 {
  color: #30679a;
  font-family: "SegoeBold";
  padding-bottom: 16px;
}
.forgot_body .col-md-12:last-child .activity_item_main.comments_main {
  border-bottom: 1px solid #e3e3e3 !important ;
}
.comment_textarea {
  margin-top: 0px;
  margin-bottom: 16px;
  height: 77px;
  resize: none;
}
.add_comment_btn {
  margin-left: 0px !important;
}
.comment_section {
  padding: 14px 0px 10px 0px;
}
.add_comment_btns {
  float: right;
}
