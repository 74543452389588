.no-border {
    border: none !important;
}

.no-border ul {
    list-style-type: disc;
    padding-left: 20px;
}
  
.no-border li.list {
    list-style-type: disc;
    padding-left: 2px;
    border: none !important;
}
  