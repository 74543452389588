.modal-input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  min-height: 400px;
}

.font-weight-black {
  font-weight: bolder;
}

.font-weight-800 {
  font-weight: 800;
}

.checkbox-medium {
  transform: scale(1.2);
}

.text-error {
  color: red;
}