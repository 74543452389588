.mm-new-form {
    /* padding: 15px;
    margin: 0px 42px;
    background-color: #e3e3e3;
    display: inline-block;
    width: 100%; */
}
.mm-calculte-text {
    /* font-size: 24px;
    margin: 15px 42px; */
}
.mm-new-form label {
    margin-bottom: 0;
}
.mm-new-form .form-control {
        width: 50%;
}
.mm-new-form .d-flex {
    justify-content: space-between;
}

.ms_width{
    width: 50%;
}
.mm-new-form .css-2b097c-container {
    width: 300px;
}